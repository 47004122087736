import React, { useEffect, useState } from 'react'
import { dateToTitleFormat } from '../../utils/DateUtils';
import { Breadcrumb, Container, Row, Col, Card } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FaAngleRight } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import Loading from '../Loading';
import { configuration } from '../../config/config';
import { getFavouriteArticle, setFavouriteArticle } from '../../utils/constent';
import ReactGA from 'react-ga4';
import heartOutlined from "../../asset/icons/heart-outline.svg"
import heartFilled from "../../asset/icons/heart-fill.svg"

const getArticleDetail = (id) => {
    return axios.get(`/resource-center-articles?id=${id}`)
        .then(res => {
            return res.data;
        })
}


const getData = (id) => {
    return axios.get(`/resource-centers?id=${id}`)
        .then(res => {
            return res.data;
        })
}

export default function ArticleDetails(props) {
    const [newData, setNewData] = useState(null);
    const [resources, setResources] = useState(null);
    const [type, setType] = useState(null);
    const [category, setCategory] = useState(null)
    const [favItem, setFavItem] = useState("")


    useEffect(() => {
        if (props.location && props.location.data) {
            setResources(props.location.data)
        } else if (props && props.match && props.match.params) {

            getData(props.match.params.id).then(res => {
                setResources(res[0]);
            })
        }
        if (props && props.match && props.match.params) {
            setType(props.match.params.type)
            setCategory(props.match.params.category)
        }
        if (props && props.location && props.location.data) {
            setNewData(props.location.data)
        } else if (props && props.match && props.match.params) {
            getArticleDetail(props.match.params.articleid).then(res => {
                setNewData(res[0]);
                const favourite = getFavouriteArticle(res[0].id)
                setFavItem(!(!favourite))
            })
        }
        window.scrollTo(0, 0);
    }, [props])

    const addToFavourite = (id) => {
        setFavItem(true)
        setFavouriteArticle(id, "true")
        ReactGA.event({ category: "FavouriteArticle", action: newData.title, label: newData.title, value: 1 })
        // ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: newData.title });
        ReactGA.send({ hitType: "event", page: window.location.pathname, title: newData.title });
        ReactGA.send({ hitType: "interest", page: window.location.pathname, title: newData.title });

    }

    const deleteFromFavourite = (id) => {
        setFavouriteArticle(id, "")
        setFavItem(false)
    }

    if (newData) {
        return (<Container fluid className="Events-container article-detail">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Article | ASME </title>
                <link rel="canonical" href={window.location.origin} />
                <script type="text/javascript">
                    {`!function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '5216654018407133');
                    fbq('track', 'PageView');
                    fbq('track', 'ViewContent');`}
                </script>
                <noscript>
                    {`<img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=5216654018407133&ev=PageView&noscript=1"
                    />`}
                </noscript>
            </Helmet>
            <Container>
                <Row>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <NavLink to="/">Home</NavLink>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <NavLink to="/resourcecenter" onClick={(e) => e.preventDefault()}>Resource Center</NavLink>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <NavLink to={`/resourcecenter/${resources?.id}`} className="text-capitalize" >{resources?.title}</NavLink>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <NavLink to={`/resourcecenter/${resources?.id}/${type}`} className="text-capitalize" >{type}</NavLink>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <NavLink to={`/resourcecenter/${resources?.id}/${type}/${category}`} className="text-capitalize" >{category}</NavLink>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <NavLink to={`/resourcecenter/${resources?.id}/${type}/${category}/${newData?.title}`} className="text-capitalize">{newData?.title}</NavLink>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
                {newData.sections && newData.sections.length > 0 && <Row className='d-xl-block d-none'>
                    <h2 className="news-room-header">Anchoring</h2>
                </Row>}
            </Container>
            <Container className="member-container">
                <Row className="">
                    {newData.sections && newData.sections.length > 0 && <Col md={4} className="d-xl-block d-none">
                        <Card style={{ top: "0%" }} className='position-sticky top-0 my-2 border-0 shadow-none'>
                            {newData.sections.map((e) => {
                                return <a href={`#heading${e.id}`} key={e.id} className="p-2 text-dark">
                                    <FaAngleRight /> {e.heading}
                                </a>
                            })}
                        </Card>
                    </Col>}
                    <Col md={8}>
                        <div className="member-container">
                            <div className='d-flex justify-content-between align-items-center'>
                                <h3 className='text-capitalize mb-0 pb-0 px-0 mr-2'>
                                    {newData?.title}
                                </h3>

                                {!favItem ?
                                    <img onClick={() => addToFavourite(newData.id)} src={heartOutlined} alt={newData.title} height={20} width={20} className="fav_icon" /> :
                                    <img onClick={() => deleteFromFavourite(newData.id)} src={heartFilled} alt={newData.title} height={20} width={20} className="fav_icon" />}
                            </div>
                            <div className="article-details">
                                <div className="article-author">{newData.created_by.firstname}{" "}{newData.created_by.lastname}</div> |
                                <div className="article-date"> Posted on {dateToTitleFormat(newData.created_at, 'MMM DD, yyyy')}</div>
                            </div>
                            <div className="article-img">
                                {newData && newData.image && newData.image.formats && newData.image.formats.large ? <Card.Img
                                    variant="top" src={configuration.strapiURL + newData.image.formats.large.url} /> :
                                    newData && newData.image && newData.image.formats && newData.image.formats.medium ? <Card.Img
                                        variant="top" src={configuration.strapiURL + newData.image.formats.medium.url} /> :
                                        newData && newData.image && newData.image.formats && newData.image.formats.small ? <Card.Img
                                            variant="top" src={configuration.strapiURL + newData.image.formats.small.url} /> : null}
                            </div>
                            <div className='overview article_desc'>
                                <ReactMarkdown>{newData.description.replaceAll("](/", `](${process.env.REACT_APP_STRAPI_URL}/`)}</ReactMarkdown>
                            </div>
                            {newData.sections.map((e) => {
                                return <section id={`heading${e.id}`} key={e.id}>
                                    <h3 className='mb-0 pb-0 px-0'>
                                        {e.heading}
                                    </h3>
                                    <div className="overview">
                                        <ReactMarkdown>{e.description.replaceAll("](/", `](${process.env.REACT_APP_STRAPI_URL}/`)}</ReactMarkdown>
                                    </div>
                                </section>
                            })}
                        </div>
                    </Col>

                </Row>
            </Container>
        </Container>
        )
    } else {
        return (
            <Loading></Loading>
        )
    }
}