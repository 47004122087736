import React, { useEffect, useState } from 'react'
import { Breadcrumb, Card, Col, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import ResourceCenterBanner from '../../asset/banner/resource-center.png';
import cardImg from '../../asset/images/feature-1.png';
import '../../stylesheets/resourcecenter.css';
import { Helmet } from 'react-helmet';
import { Box } from '@material-ui/core';
import axios from 'axios';

const getData = (id) => {
    return axios.get(`/resource-centers?id=${id}`)
        .then(res => {
            return res.data;
        })
}

export default function Categories(props) {
    const [type, setType] = useState(null);
    const [resources, setResources] = useState(null);

    useEffect(() => {
        window.scroll(0, 0)
        if (props.location && props.location.data) {
            setResources(props.location.data)
        } else if (props && props.match && props.match.params) {

            getData(props.match.params.id).then(res => {
                setResources(res[0]);
            })
        }
        if (props && props.match && props.match.params) {
            setType(props.match.params.type)
        }
    }, [props])


    const DATA = [
        { title: "Tools", id: "tools", desc: "While it took the social media giant Facebook to reach its peak from 2004, Tiktok was launched just 5 years ago in 2016, now with three billion users worldwide." },
        { title: "How To", id: "howto", desc: "Constructing a successful team filled with talented hires that are self-motivated and loyal to the company is every CEO's dream. Imagine having to go through a myraid of resumes and interviews to find the perfect employee but within months, receiving their resignation letter. All those resources wasted." },
        { title: "Resources", id: "resources", desc: "Without a doubt, many companies pump in a lot of budget and effort to build their company's brand awareness because if this is done right, you will naturally generate more potential leads, more people will start to be aware of your brand and might even choose you over your competitors" },
        { title: "Perspective", id: "perspective", desc: "A well executed advertising campaign can drive a business's brand awareness. bran image and sales to desirable heights yet it can also cost a fortune if budgets are not utilised budgets on areas such ppc, impressions, design, are often the main reasons for the high advertising cost. Without a clear agenda set before launching any campaigns, it can not only lead to low engagement rates but also wasted funds and efforts." }
    ]

    return (<Container fluid className="Events-container">
        <Helmet>
            <meta charSet="utf-8" />
            <title>Resource Center | ASME </title>
            <link rel="canonical" href={window.location.origin} />
            <script type="text/javascript">
                {`!function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '5216654018407133');
                    fbq('track', 'PageView');
                    fbq('track', 'ViewContent');`}
            </script>
            <noscript>
                {`<img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=5216654018407133&ev=PageView&noscript=1"
                    />`}
            </noscript>
        </Helmet>
        <Container fluid className="banner-container">
            <img src={ResourceCenterBanner} alt="Event Banner" width="100%" className="banner-img"></img>
            <h1>Resource Center</h1>
        </Container>
        <Container>
            <Row>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <NavLink to="/">Home</NavLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <NavLink to="/resourcecenter" onClick={(e) => e.preventDefault()}>Resource Center</NavLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <NavLink to={`/resourcecenter/${resources?.id}`} className="text-capitalize" >{resources?.title}</NavLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <NavLink to={`/resourcecenter/${resources?.id}/${type}`} className="text-capitalize" onClick={(e) => e.preventDefault()}>{type}</NavLink>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Row>
        </Container>
        <Container className="member-container resource-center">
            <h2 className="news-room-header px-0 mx-0 text-capitalize">{type}</h2>
            <Row className='justify-content-center row'>
                {DATA.map((e, i) => {
                    return <Col className='px-2 resource-card' md={3} key={i}>
                        <Card className="event-card">
                            <Card.Img variant="top" src={cardImg} />
                            <Card.Body>
                                <Card.Text>
                                    <div className="resource-center-title res">{e.title}</div>
                                    <div className="overview">{e.desc}</div>
                                </Card.Text>
                                {resources?.id && <NavLink role="button" to={`/resourcecenter/${resources?.id}/${type}/${e.id}`} className="resource-readmore">Read More</NavLink>}
                            </Card.Body>
                        </Card>
                    </Col>
                })}
            </Row>

            <Row className='resource-center-bottom'>
                <Col className='px-2 resource-card' md={3}>
                    <Box className="">
                        <div className="overview">
                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem
                        </div>
                    </Box>
                </Col>
                <Col className='px-2 resource-card' md={3}>
                    <Box className="">
                        <div className="overview">
                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem
                        </div>
                    </Box>
                </Col>
            </Row>
        </Container>
    </Container>
    )
}