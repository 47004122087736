import React, { useEffect, useState } from 'react'
import { Breadcrumb, Col, Container, Row, Card, Button } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import '../stylesheets/events.css';
import EventBanner from '../asset/banner/event-banner.png';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { dateToTitleFormat } from '../utils/DateUtils';
import { FaMapMarker, FaRegClock } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { configuration } from '../config/config';
import { Helmet } from 'react-helmet';
import Loading from './Loading';
const getEvents = (number) => {
    return axios.get(`/events-v-2-s?_sort=date&_limit=${number}`)
        .then(res => {
            return res.data;
        });
}
const getEventsFiltered = (filter) => {
    return axios.get(`/events-v-2-s?${filter}`)
        .then(res => {
            return res.data;
        });
}
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function Events({ history }) {
    let filter = {
        startDate: '',
        endDate: '',
        type: ''
    };
    const [dataReceived, setdataReceived] = useState(false);
    const [numberOfTiles, setNumberOfTiles] = useState(9);
    const [noMoreToLoad, setNoMoreToLoad] = useState(true);
    const classes = useStyles();
    const [filters, setFilters] = useState(filter)
    const updateFilterData = (event, type) => {
        let filter = { ...filters };
        if (type === "startDate") {
            filter.startDate = event;
            setFilters(filter);
        } else if (type === "endDate") {
            filter.endDate = event;
            setFilters(filter);
        } else {
            filter.type = event;
            setFilters(filter);
        }
    };
    const filterEvents = () => {
        let query = '';
        if (filters.startDate !== '') {
            query = query + "date_gte=" + dateToTitleFormat(filters.startDate, 'yyyy-MM-DD');
        }
        if (filters.endDate !== '') {
            if (query === '') {
                query = query + "date_lte=" + dateToTitleFormat(filters.endDate, 'yyyy-MM-DD');
            } else {
                query = query + "&date_lte=" + dateToTitleFormat(filters.endDate, 'yyyy-MM-DD')
            }
        }
        if (filters.type !== '') {
            if (query === '') {
                query = query + "Eventtype=" + filters.type;
            } else {
                query = query + "&Eventtype=" + filters.type;
            }
        }
        getEventsFiltered(query).then(res => {
            setData(res);
            if (res.length < 9) {
                setNoMoreToLoad(false)
            }
            setdataReceived(true);
        }
        )
    }
    const [data, setData] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0)
        getEvents(numberOfTiles)
            .then(res => {
                setData(res);
                if (res.length < 9) {
                    setNoMoreToLoad(false)
                }
                setdataReceived(true);
            })
    }, []);
    const updateTiles = () => {
        setNumberOfTiles(numberOfTiles + 9)
        getEvents(numberOfTiles + 9).then(res => {
            setData(res);
            if (res.length < numberOfTiles + 9) {
                setNoMoreToLoad(false)
            }
            setdataReceived(true);
        })
    }
    const getDateElement = (date, type) => {
        const data = dateToTitleFormat(date, 'DD MMM, yyyy');
        if (type === "date" && data !== "TBD") {
            return data.split(' ')[0]
        } else {
            return data.split(' ')[1] + " " + data.split(' ')[2];
        }
    }
    const readMore = (item) => {
        history.push({ pathname: `/article/event/${item.id}/${encodeURI(item.eventname)}`, state: { item } });
    }
    if (data && data.length > 0) {
        return (
            <Container fluid className="Events-container">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Events | ASME </title>
                    <link rel="canonical" href={window.location.origin} />
                    <script type="text/javascript">
                    {`!function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '5216654018407133');
                    fbq('track', 'PageView');
                    fbq('track', 'ViewContent');`}
                    </script>
                    <noscript>
                    {`<img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=5216654018407133&ev=PageView&noscript=1"
                    />`}
                    </noscript>
                </Helmet>
                <Container fluid className="banner-container">
                    <img src={EventBanner} alt="Event Banner" width="100%" className="banner-img"></img>
                    <h1>Events</h1>
                </Container>
                <Container>
                    <Row>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <NavLink to="/">Home</NavLink></Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <NavLink to="/events">Events</NavLink>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Row>
                    <Row className="event-date-filters">
                        <Col md={10}>
                            <Row>
                                <Col md={4} className="filter-section">
                                    <TextField
                                        id="date"
                                        label="Start Date"
                                        type="date"
                                        onChange={(e) => updateFilterData(e.target.value, 'startDate')}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }} />
                                </Col>
                                <Col md={4} className="filter-section">
                                    <TextField
                                        id="date"
                                        label="End Date"
                                        type="date"
                                        className={classes.textField}
                                        onChange={(e) => updateFilterData(e.target.value, 'endDate')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }} /></Col>
                                <Col md={4} className="filter-section">
                                    <label className="MuiFormLabel-root MuiInputLabel-root 
                                MuiInputLabel-formControl MuiInputLabel-animated 
                                MuiInputLabel-shrink MuiFormLabel-filled eventType-select-label">Event Type</label>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={filters.type}
                                        className="eventType-select"
                                        onChange={(e) => updateFilterData(e.target.value, 'type')}
                                        label="Name"
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="Conference">Conference</MenuItem>
                                        <MenuItem value="Seminar">Seminar</MenuItem>
                                        <MenuItem value="Exhibition">Exhibition</MenuItem>
                                        <MenuItem value="Summit">Summit</MenuItem>
                                        <MenuItem value="Trade Show">Trade Show</MenuItem>
                                        <MenuItem value="Miscellaneous">Miscellaneous</MenuItem>
                                    </Select>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={2}>
                            <Row><Col md={12} className="event-filter-cont"><Button variant="primary" onClick={(e) => filterEvents()} className="filter-event">Find Events</Button></Col></Row>
                        </Col>
                    </Row>
                </Container>
                <Container className="member-container">
                    <Row>
                        {data.map((value, index) => {
                            return <Col md={4} className="event-container">
                                <Card onClick={(e) => readMore(value)} className="event-card">
                                    {value && value.tileImage && value.tileImage.length > 0 && value.tileImage[0].formats.small ? <Card.Img variant="top" src={configuration.strapiURL + value.tileImage[0].formats.small.url} /> : value.tileImage && value.tileImage.length > 0 && value.tileImage[0].formats.large ? <Card.Img variant="top" src={configuration.strapiURL + value.tileImage[0].formats.large.url} /> :
                                        value && value.tileImage && value.tileImage.length > 0 && value.tileImage[0].formats.small ? <Card.Img variant="top" src={configuration.strapiURL + value.tileImage[0].formats.small.url} /> : value.tileImage && value.tileImage.length > 0 && value.tileImage[0].formats.medium ? <Card.Img variant="top" src={configuration.strapiURL + value.tileImage[0].formats.medium.url} /> :
                                            value && value.tileImage && value.tileImage.length > 0 && value.tileImage[0].formats.small ? <Card.Img variant="top" src={configuration.strapiURL + value.tileImage[0].formats.small.url} /> : value.tileImage && value.tileImage.length > 0 && value.tileImage[0].formats.small ? <Card.Img variant="top" src={configuration.strapiURL + value.tileImage[0].formats.small.url} /> : <Card.Img variant="top" />}
                                    <div className="event-date"><div className="event-month-int">{getDateElement(value.date, "date")}</div><div className="event-month">{getDateElement(value.date)}</div></div>
                                    <Card.Body>
                                        <Card.Title>{value.Eventtype}</Card.Title>
                                        <Card.Text>
                                            <div className="event-title">{value.eventname}</div>
                                            <div className="event-details"><FaRegClock></FaRegClock> {dateToTitleFormat(value.date, 'MMM DD, yyyy')} @ {value.time}</div>
                                            <div className="event-details"><FaMapMarker></FaMapMarker>{value.address}</div>
                                        </Card.Text>
                                        <Button variant="primary" onClick={(e) => readMore(value)}>More Details</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        })}
                    </Row>
                    {noMoreToLoad && <Row className="load-more">
                        <Col md={12}>
                            <Button variant="primary" onClick={updateTiles}>Load More</Button>
                        </Col>
                    </Row>}
                </Container>
            </Container>
        )
    } else {
        return <Loading noData={dataReceived}></Loading>
    }
}
