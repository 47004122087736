import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Main from './components/Main';
import ElectronicPaySlip from './components/pmax/ElectronicPaySlip';
import EmployeeHandbook from './components/pmax/EmployeeHandbook';
import EmployeePerformance from './components/pmax/EmployeePerformance';
import PmaxHomePage from './components/pmax/PmaxHomePage';
import {
  Switch,
  Route,
  BrowserRouter as Router,
} from 'react-router-dom';
import ReactGA from 'react-ga4';
import axios from 'axios';
import { configuration } from './config/config';
import EpaySlip from './components/pmax/EpaySlip';
import Eperformance from './components/pmax/Eperformance';
import Ehandbook from './components/pmax/Ehandbook';
import Alldownloads from './components/pmax/Alldownloads';
import ThankYou from './components/pmax/ThankYou';
import ThankYouAlternate from './components/pmax/ThankYouAlternate';
import { useEffect } from 'react';

ReactGA.initialize(process.env.REACT_APP_ANALYTICS_TRACKING_ID);

axios.interceptors.request.use(c => {
  let header = {
    // "Authorization": "Bearer " + configuration.jwtToken,
    "Accept": "application/json"
  };
  c.url = `${configuration.strapiURL}` + c.url;
  c.headers = header
  return c;
})

function App() {

  useEffect(() => { }, []);

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route
            exact
            path="/pmax/homepage"
            render={props => <PmaxHomePage {...props} />}
          ></Route>
          <Route
            exact
            path="/pmax/payslip"
            render={props => <ElectronicPaySlip {...props} />}
          ></Route>
          <Route
            exact
            path="/pmax/performance"
            render={props => <EmployeePerformance {...props} />}
          ></Route>
          <Route
            exact
            path="/pmax/handbook"
            render={props => <EmployeeHandbook {...props} />}
          ></Route>
          <Route
            path="/pmax/epayslip"
            render={props => <EpaySlip {...props} />}
          ></Route>

          <Route
            path="/pmax/ehandbook"
            render={props => <Ehandbook {...props} />}
          ></Route>

          <Route
            path="/pmax/eperformance"
            render={props => <Eperformance {...props} />}
          ></Route>

          <Route
            path="/pmax/downloadAll"
            render={props => <Alldownloads {...props} />}
          ></Route>
          <Route
            path="/pmax/thankyou"
            render={props => <ThankYou {...props} />}
          ></Route>
          <Route
            path="/pmax/thankyou-performance"
            render={props => <ThankYouAlternate {...props} />}
          ></Route>
          <Route
            path="/pmax/thankyou-handbook"
            render={props => <ThankYouAlternate {...props} />}
          ></Route>
          <Route
            path="/pmax/thankyou-payslip"
            render={props => <ThankYouAlternate {...props} />}
          ></Route>

          <Route
            path="/"
            render={props => <Main {...props} />}
          ></Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
