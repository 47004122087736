const getFavouriteArticle = (id) => {
    return localStorage.getItem("rca" + id)
}
const setFavouriteArticle = (id, value) => {
    localStorage.setItem("rca" + id, value)
}

export {
    getFavouriteArticle,
    setFavouriteArticle
}