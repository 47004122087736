import React, { useEffect, useState } from 'react'
import { Breadcrumb, Col, Container, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
import { configuration } from '../config/config';
import { dateToTitleFormat } from '../utils/DateUtils';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import Loading from './Loading';
import { Helmet } from 'react-helmet';
const getLeftAds = () => {
    return axios.get('/article-page-ads-left').then(res => { return res.data })
}
const getRigthAds = () => {
    return axios.get('/article-page-ads-right').then(res => { return res.data })
}
const getNews = (id) => {
    return axios.get(`/newsrooms/${id}`)
        .then(res => {
            return res.data;
        })
}
const getEvents = (id) => {
    return axios.get(`/events-v-2-s/${id}`)
        .then(res => {
            return res.data;
        });
}
const returnNavlink = (title) => {
    if (title === "public-service-announcements") {
        return <NavLink to='/publicserviceannouncement'>Public Service Announcement</NavLink>
    } else if (title === "newsroom") {
        return <NavLink to='/newsroom'>Newsroom</NavLink>
    } else if (title === "feature-member") {
        return <NavLink to='/featuredMembers'>Featured Members</NavLink>
    } else if (title === "event") {
        return <NavLink to='/events'>Events</NavLink>
    }
}
const getFeaturedMembers = (id) => {
    return axios.get(`/featured-members/${id}`)
        .then(res => {
            return res.data;
        });

}
const getPublicServiceAnnouncements = (id) => {
    return axios.get(`/public-service-announcements/${id}`)
        .then(res => {
            return res.data;
        });

}
export default function Article(props) {
    const [data, setData] = useState(null);
    const [leftAds, setLeftAds] = useState(null);
    const [rightAds, setRightAds] = useState(null);
    const [type, setType] = useState(null);
    useEffect(() => {
        window.scrollTo(0, 0);
        if (props && props.location && props.location.state && props.location.state.item) {
            if (props.location && props.location.state && props.location.state) {
                let item = props.location.state.item;
                let type = props.match.params.type;
                setType(type);
                if (props.match.params.type === "feature-member") {
                    const articleData = {
                        heading: item.Name,
                        description: item.description,
                        image: item && item.tileimage[0] ? (item.tileimage && item.tileimage[0].formats.large) ? (configuration.strapiURL + item.tileimage[0].formats.large.url) : (configuration.strapiURL + item.tileimage[0].formats.small.url) : item.tileimage && item.tileimage.format && item.tileimage.format.large ? (configuration.strapiURL + item.tileimage.formats.large.url) : item.tileimage && item.tileimage.format && item.tileimage.format.small ? (configuration.strapiURL + item.tileimage.formats.small.url) : (configuration.strapiURL + item.tileimage.formats.thumbnail.url),
                        para1: item.ParagraphFirst,
                        para2: item.ParagraphSecond,
                        para3: item.ParagraphThird,
                        author: item.author,
                        date: dateToTitleFormat(item.date, 'MMM DD, yyyy'),
                        otherStories: item.other_stories,
                        disclamier: item.disclamier,
                        imageCaption: item.imageCaption,
                        Ogurl: item.ogurl,
                        CanonicalTag: item.CanonicalTag,
                        ogdesc: item.MetaDescription_OGdescription,
                        ogimage: item.ogimage
                    }
                    setData(articleData);
                } else if (props.match.params.type === "event") {
                    const articleData = {
                        heading: item.eventname,
                        description: item.tileDescription,
                        image: (item.tileImage && item.tileImage[0].formats && item.tileImage[0].formats.large) ?
                            (configuration.strapiURL + item.tileImage[0].formats.large.url) : (item.tileImage && item.tileImage[0].formats && item.tileImage[0].formats.medium) ? (configuration.strapiURL + item.tileImage[0].formats.medium.url) :
                                (item.tileImage && item.tileImage[0].formats && item.tileImage[0].formats.small) ? (configuration.strapiURL + item.tileImage[0].formats.small.url) : null,
                        para1: item.ParagraphFirst,
                        para2: item.ParagraphSecond,
                        para3: item.ParagraphThird,
                        author: item.author,
                        date: dateToTitleFormat(item.date, 'MMM DD, yyyy'),
                        otherStories: item.other_stories,
                        disclamier: item.disclamier,
                        imageCaption: item.imageCaption,
                        Ogurl: item.ogurl,
                        CanonicalTag: item.CanonicalTag,
                        ogdesc: item.MetaDescription_OGdescription,
                        ogimage: item.ogimage
                    }
                    setData(articleData);
                } else {
                    const articleData = {
                        heading: item.title,
                        description: item.tileDescription,
                        image: (item.tileImage && item.tileImage[0].formats && item.tileImage[0].formats.large) ?
                            (configuration.strapiURL + item.tileImage[0].formats.large.url) : (item.tileImage && item.tileImage[0].formats && item.tileImage[0].formats.medium) ? (configuration.strapiURL + item.tileImage[0].formats.medium.url) :
                                (item.tileImage && item.tileImage[0].formats && item.tileImage[0].formats.small) ? (configuration.strapiURL + item.tileImage[0].formats.small.url) : null,
                        para1: item.ParagraphFirst,
                        para2: item.ParagraphSecond,
                        para3: item.ParagraphThird,
                        author: item.author,
                        date: dateToTitleFormat(item.date, 'MMM DD, yyyy'),
                        otherStories: item.other_stories,
                        disclamier: item.disclamier,
                        imageCaption: item.imageCaption,
                        Ogurl: item.ogurl,
                        CanonicalTag: item.CanonicalTag,
                        ogdesc: item.MetaDescription_OGdescription,
                        ogimage: item.ogimage
                    }
                    setData(articleData);
                }
            }
        } else {
            //API Calls to get the article if it doesn't exist
            if (props.match.params.type === "feature-member") {
                getFeaturedMembers(props.match.params.id).then(res => {
                    let item = res;
                    const articleData = {
                        heading: item.Name,
                        description: item.description,
                        image: item && item.tileimage[0] ? (item.tileimage && item.tileimage[0].formats.large) ? (configuration.strapiURL + item.tileimage[0].formats.large.url) : (configuration.strapiURL + item.tileimage[0].formats.small.url) : item.tileimage && item.tileimage.format && item.tileimage.format.large ? (configuration.strapiURL + item.tileimage.formats.large.url) : item.tileimage && item.tileimage.format && item.tileimage.format.small ? (configuration.strapiURL + item.tileimage.formats.small.url) : (configuration.strapiURL + item.tileimage.formats.thumbnail.url),
                        para1: item.ParagraphFirst,
                        para2: item.ParagraphSecond,
                        para3: item.ParagraphThird,
                        author: item.author,
                        date: dateToTitleFormat(item.date, 'MMM DD, yyyy'),
                        otherStories: item.other_stories,
                        disclamier: item.disclamier,
                        imageCaption: item.imageCaption,
                        Ogurl: item.ogurl,
                        CanonicalTag: item.CanonicalTag,
                        ogdesc: item.MetaDescription_OGdescription,
                        ogimage: item.ogimage
                    }
                    setData(articleData);
                })
            } else if (props.match.params.type === "event") {
                getEvents(props.match.params.id).then(res => {
                    let item = res;
                    const articleData = {
                        heading: item.eventname,
                        description: item.tileDescription,
                        image: (item.tileImage && item.tileImage[0].formats.large) ? (configuration.strapiURL + item.tileImage[0].formats.large.url) : (configuration.strapiURL + item.tileImage[0].formats.small.url),
                        para1: item.ParagraphFirst,
                        para2: item.ParagraphSecond,
                        para3: item.ParagraphThird,
                        author: item.author,
                        date: dateToTitleFormat(item.date, 'MMM DD, yyyy'),
                        otherStories: item.other_stories,
                        disclamier: item.disclamier,
                        imageCaption: item.imageCaption,
                        Ogurl: item.ogurl,
                        CanonicalTag: item.CanonicalTag,
                        ogdesc: item.MetaDescription_OGdescription,
                        ogimage: item.ogimage
                    }
                    setData(articleData);
                })
            } else if (props.match.params.type === "newsroom") {
                getNews(props.match.params.id).then(res => {
                    let item = res;
                    const articleData = {
                        heading: item.title,
                        description: item.tileDescription,
                        image: (item.tileImage && item.tileImage[0].formats.large) ? (configuration.strapiURL + item.tileImage[0].formats.large.url) : (configuration.strapiURL + item.tileImage[0].formats.small.url),
                        para1: item.ParagraphFirst,
                        para2: item.ParagraphSecond,
                        para3: item.ParagraphThird,
                        author: item.author,
                        date: dateToTitleFormat(item.date, 'MMM DD, yyyy'),
                        otherStories: item.other_stories,
                        disclamier: item.disclamier,
                        imageCaption: item.imageCaption,
                        Ogurl: item.ogurl,
                        CanonicalTag: item.CanonicalTag,
                        ogdesc: item.MetaDescription_OGdescription,
                        ogimage: item.ogimage
                    }
                    setData(articleData);
                })
            } else if (props.match.params.type === "public-service-annoumcents") {
                getPublicServiceAnnouncements(props.match.params.id).then(res => {
                    let item = res;
                    const articleData = {
                        heading: item.title,
                        description: item.tileDescription,
                        image: (item.tileImage && item.tileImage[0].formats.large) ? (configuration.strapiURL + item.tileImage[0].formats.large.url) : (configuration.strapiURL + item.tileImage[0].formats.small.url),
                        para1: item.ParagraphFirst,
                        para2: item.ParagraphSecond,
                        para3: item.ParagraphThird,
                        author: item.author,
                        date: dateToTitleFormat(item.date, 'MMM DD, yyyy'),
                        otherStories: item.other_stories,
                        disclamier: item.disclamier,
                        imageCaption: item.imageCaption,
                        Ogurl: item.ogurl,
                        CanonicalTag: item.CanonicalTag,
                        ogdesc: item.MetaDescription_OGdescription,
                        ogimage: item.ogimage
                    }
                    setData(articleData);
                })
            }
        }
        getLeftAds().then(res => setLeftAds(res));
        getRigthAds().then(res => setRightAds(res));
    }, [props.location.state ? props.location.state.item : null])

    const navigateToNewArticle = (item) => {
        let title = item.title ? item.title : item.Name ? item.Name : item.eventname ? item.eventname : 'asme-article';
        props.history.push({ pathname: `/article/${props.match.params.type}/${item.id}/${encodeURI(title)}`, state: { item } });
    }
    const navigateToLink = (url) => {
        window.open(
            url,
            '_blank' // <- This is what makes it open in a new window.
        );
    }
    if (data) {
        return (
            <Container fluid className="Events-container">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{data.heading} </title>
                    <meta property="og:title" content={data.heading} />
                    <meta property="og:url" content={data.Ogurl} />
                    <link rel="canonical" href={data.CanonicalTag} />
                    <meta property="og:description" content={data.ogdesc} />
                    <meta property="og:image" content={data.ogimage} />
                    <script type="text/javascript">
                        {`!function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '5216654018407133');
                    fbq('track', 'PageView');
                    fbq('track', 'ViewContent');`}
                    </script>
                    <noscript>
                        {`<img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=5216654018407133&ev=PageView&noscript=1"
                    />`}
                    </noscript>
                </Helmet>
                <Container>
                    <Row>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <NavLink to="/">Home</NavLink></Breadcrumb.Item>
                            <Breadcrumb.Item>
                                {props && props.match && props.match.params && returnNavlink(props.match.params.type)}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <NavLink to="/">{data.heading}</NavLink>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Row>
                </Container>
                <Container className="member-container">
                    <h1>
                        {data.heading}
                    </h1>
                    <div className="overview">
                        <ReactMarkdown>{data.description}</ReactMarkdown>
                    </div>
                    <div className="article-details">
                        <div className="article-author">{data.author}</div> |
                        <div className="article-date"> Posted on {data.date}</div>
                    </div>
                    <div className="article-img">
                        <img src={data.image} alt="Main"></img>
                        <div className="article-title-image-desc">
                            {data.imageCaption ? <blockquote><ReactMarkdown source={data.imageCaption}></ReactMarkdown> </blockquote> : null}
                        </div>
                    </div>
                    <Row>
                        <Col md={9} className="article-section">
                            <br />
                            <ReactMarkdown>
                                {data.para1 ? data.para1.replaceAll("](/", `](${process.env.REACT_APP_STRAPI_URL}/`) : null}
                            </ReactMarkdown>
                            <br />
                            <div>
                                {leftAds && leftAds.advertisementOne.formats && leftAds.advertisementOne.formats.large && <img onClick={(e) => navigateToLink(leftAds.advertisementOneUrl)} src={configuration.strapiURL + leftAds.advertisementOne.formats.large.url} width="100%" alt="Article Ad"></img>}
                            </div>
                            <br />
                            <ReactMarkdown>
                                {data.para2 ? data.para2.replaceAll("](/", `](${process.env.REACT_APP_STRAPI_URL}/`) : null}
                            </ReactMarkdown>
                            <br />
                            <div className="article-img">
                                {leftAds && leftAds.advertisementTwo && leftAds.advertisementTwo.formats && leftAds.advertisementTwo.formats.large ? <img src={configuration.strapiURL + leftAds.advertisementTwo.formats.large.url} width="100%" alt="Article Ad"></img>
                                    : leftAds && leftAds.advertisementTwo && leftAds.advertisementTwo.formats && leftAds.advertisementTwo.formats.medium ? <img src={configuration.strapiURL + leftAds.advertisementTwo.formats.medium.url} width="100%" alt="Article Ad"></img> :
                                        leftAds && leftAds.advertisementTwo && leftAds.advertisementTwo.formats && leftAds.advertisementTwo.formats.thumbnail ? <img src={configuration.strapiURL + leftAds.advertisementTwo.formats.thumbnail.url} width="100%" alt="Article Ad"></img> : null}
                            </div>
                            <br />
                            <ReactMarkdown>
                                {data.para3 ? data.para3.replaceAll("](/", `](${process.env.REACT_APP_STRAPI_URL}/`) : null}
                            </ReactMarkdown>
                            <div className="disclamier">
                                <em>{data.disclamier}</em>
                            </div>
                            <hr></hr>
                            {data.otherStories && data.otherStories.length > 0 &&
                                <> <div>Other Stories</div>
                                    {
                                        data.otherStories.map(items => {
                                            return <> <Row onClick={(e) => navigateToNewArticle(items)}>
                                                <Col md={4}>
                                                    {props.match.params.type === "feature-member" ? items.tileimage && <img src={configuration.strapiURL + items.tileimage[0].formats.thumbnail.url} width="100%" alt="Article Main other"></img>
                                                        : items && items.tileImage && <img src={configuration.strapiURL + items.tileImage[0].formats.thumbnail.url} width="100%" alt="Article Main other"></img>}
                                                </Col>
                                                {props.match.params.type === "event" ?
                                                    <Col md={8}>
                                                        <div className="article-other-title">{items.eventname}</div>
                                                        <div className="article-other-overview">{items.Eventtype}</div>
                                                        <div className="article-other-date">{dateToTitleFormat(items.date, 'MMM DD, yyyy')}</div>
                                                    </Col>
                                                    :
                                                    <Col md={8}>
                                                        <div className="article-other-title">{type === "feature-member" ? items.Name : items.Name ? items.Name : items.title}</div>
                                                        {type === "feature-member" ? <div className="article-other-overview">{items.title}</div> : null}
                                                        <div className="article-other-overview">{type === "feature-member" ? items.organisation : items.tileDescription}</div>
                                                        <div className="article-other-date">{dateToTitleFormat(items.date, 'MMM DD, yyyy')}</div>
                                                    </Col>}
                                            </Row>
                                                <hr>
                                                </hr>
                                            </>
                                        })
                                    }
                                </>}

                        </Col>
                        <Col md={3} className="article-right-ad">
                            <Row>
                                <Col md={12}>
                                    {rightAds && rightAds.advertisementOne.formats && rightAds.advertisementOne.formats.large && <img onClick={(e) => navigateToLink(rightAds.advertisementOneUrl)} src={configuration.strapiURL + rightAds.advertisementOne.formats.large.url} width="100%" alt="Article Ad"></img>}
                                </Col>
                                <Col md={12}>
                                    {rightAds && rightAds.advertisementTwo.formats && rightAds.advertisementTwo.formats.large ? <img onClick={(e) => navigateToLink(rightAds.advertisementTwoUrl)} src={configuration.strapiURL + rightAds.advertisementTwo.formats.large.url} width="100%" alt="Article Ad"></img> :
                                        rightAds && rightAds.advertisementTwo.formats && rightAds.advertisementTwo.formats.medium ? <img onClick={(e) => navigateToLink(rightAds.advertisementTwoUrl)} src={configuration.strapiURL + rightAds.advertisementTwo.formats.medium.url} width="100%" alt="Article Ad"></img> : null}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>
        )
    } else {
        return <Loading></Loading>
    }
}