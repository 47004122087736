import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Breadcrumb, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import AboutUsBanner from '../asset/banner/aboutus-banner.png';
import Loading from './Loading';
import ReactMarkdown from 'react-markdown';
import { Helmet } from 'react-helmet';
const getPrivacyPolicy = () => {
    return axios.get(`/privacy-statement`).then(res => {
        return res.data;
    })
}

export default function PrivacyStatement() {
    const [privacyStatement, setPrivacyStatement] = useState(null);
    useEffect(() => {
        window.scrollTo(0, 0);
        getPrivacyPolicy().then(res => {
            setPrivacyStatement(res);
        })
    }, [])
    if (privacyStatement) {
        return (
            <>
                <Helmet>
                    <script type="text/javascript">
                        {`!function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '5216654018407133');
                    fbq('track', 'PageView');
                    fbq('track', 'ViewContent');`}
                    </script>
                    <noscript>
                        {`<img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=5216654018407133&ev=PageView&noscript=1"
                    />`}
                    </noscript>
                </Helmet>
                <Container fluid className="Events-container">
                    <Container fluid className="banner-container">
                        <img src={AboutUsBanner} alt="Event Banner" width="100%" className="banner-img"></img>
                        <h1>Privacy Statement</h1>
                    </Container>
                    <Container>
                        <Row>
                            <Breadcrumb>
                                <Breadcrumb.Item ><NavLink to="/">Home</NavLink></Breadcrumb.Item>
                                <Breadcrumb.Item href="/resource-center">
                                    <NavLink to="/privacystatement">Privacy Statement</NavLink>
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    </Container>
                    <Container className="internal-container">
                        <ReactMarkdown>
                            {privacyStatement.content.replaceAll("](/", `](${process.env.REACT_APP_STRAPI_URL}/`)}
                        </ReactMarkdown>
                    </Container>
                </Container></>
        )
    } else {
        return <Loading></Loading>;
    }
}
