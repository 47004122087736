import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Card,
  Carousel,
  Modal
} from "react-bootstrap";
import {
  FaAngleDown,
  FaAngleRight,
  FaMapMarker,
  FaRegClock
} from "react-icons/fa";
import { Helmet } from "react-helmet";
import axios from "axios";

import Bag from "./../asset/icons/bag.png";
import Book from "./../asset/icons/book.png";
import Badge from "./../asset/icons/badge.png";
import People from "./../asset/icons/people.png";
import { configuration } from "../config/config";
import { dateToTitleFormat } from "../utils/DateUtils";

import "../stylesheets/events.css";

const getBannerImage = () => {
  return axios.get("/hero-banner-image").then((res) => {
    return res.data;
  });
};

const getFullWidthBannerAd = () => {
  return axios.get("/homepage-ad-banner").then((res) => {
    return res.data;
  });
};

const getBannerCollection = () => {
  return axios.get("/home-page-add-banner-collections").then((res) => {
    return res.data;
  });
};

const getBannerCollection2 = () => {
  return axios.get("/home-page-add-banner-collection-2-s").then((res) => {
    return res.data;
  });
};

const getEvents = () => {
  return axios.get(`/events-v-2-s?_sort=date&_limit=2`).then((res) => {
    return res.data;
  });
};

const getNews = () => {
  return axios.get("/newsrooms?_limit=3").then((res) => {
    return res.data;
  });
};

const getBannerHeroImage = () => {
  return axios.get("/banner-hero-image").then((res) => {
    return res.data;
  });
};

const getQuickLinkLeft = () => {
  return axios.get(`/quick-links-lefts`).then((res) => {
    return res.data;
  });
};

const getQuickLinkRight = () => {
  return axios.get(`/quick-links-rights`).then((res) => {
    return res.data;
  });
};

const getEventAdRight = () => {
  return axios.get(`/home-page-event-right-ad`).then((res) => {
    return res.data;
  });
};

const getHomePageFullLengthAd2 = () => {
  return axios.get(`/home-page-full-length-ad-2`).then((res) => {
    return res.data;
  });
};

const getHomePageSlider = () => {
  return axios.get(`/featured-members`).then((res) => {
    return res.data;
  });
};

const getColumn = (limit, AllRow, readMoreFeaturedMember) => {
  let col = [];
  const maxUpto = limit - 4;
  if (limit > AllRow.length) {
    limit = AllRow.length;
  }
  for (let j = maxUpto; j < limit; j++) {
    if (AllRow[j]) {
      col.push(
        <Col
          onClick={(e) => readMoreFeaturedMember(AllRow[j])}
          md={3}
          className="member-highlight"
        >
          {AllRow &&
            AllRow[j].tileimage &&
            AllRow[j].tileimage[0].formats &&
            AllRow[j].tileimage[0].formats.medium ? (
            <img
              className="featured-hp-mem"
              src={
                configuration.strapiURL +
                AllRow[j].tileimage[0].formats.medium.url
              }
              width="100%"
              height="100%"
              alt="Annoucment"
            />
          ) : AllRow &&
            AllRow[j].tileimage &&
            AllRow[j].tileimage[0].formats &&
            AllRow[j].tileimage[0].formats.small ? (
            <img
              className="featured-hp-mem"
              src={
                configuration.strapiURL +
                AllRow[j].tileimage[0].formats.small.url
              }
              width="100%"
              height="100%"
              alt="Annoucment"
            />
          ) : AllRow &&
            AllRow[j].tileimage &&
            AllRow[j].tileimage[0].formats &&
            AllRow[j].tileimage[0].formats.thumbnail ? (
            <img
              className="featured-hp-mem"
              src={
                configuration.strapiURL +
                AllRow[j].tileimage[0].formats.thumbnail.url
              }
              width="100%"
              height="100%"
              alt="Annoucment"
            />
          ) : null}
          <div className="hp-highlight-member-detail">
            <div className="member-name">
              {AllRow[j] ? AllRow[j]["Name"] : ""}
            </div>
            <div className="member-designation">
              {AllRow[j] ? AllRow[j]["title"] : ""}
            </div>
            <div className="member-org">
              {AllRow[j] ? AllRow[j]["organisation"] : ""}
            </div>
          </div>
        </Col>
      );
    }
  }
  return col;
};

const sliders = (row, values, readMoreFeaturedMember) => {
  let slidee = [];
  for (let i = 1; i <= row; i++) {
    slidee.push(
      <Carousel.Item>
        <Row>{getColumn(i * 4, values, readMoreFeaturedMember)}</Row>
      </Carousel.Item>
    );
  }
  return slidee;
};

export default function HomePage({ history, banner }) {
  const [displayBanner, setBannerDisplay] = useState(true);
  const [adBannerMobile, setBanner] = useState(false);
  const [bannerImage, setBannerImage] = useState([]);
  const [fullWidthAd, setFullWidthAd] = useState(null);
  const [events, setEvents] = useState([]);
  const [news, setNews] = useState([]);
  const [heroImage, setHeroImage] = useState(null);
  const [quickLinkLeft, setQuickLinkLeft] = useState(null);
  const [quickLinkRight, setQuickLinkRight] = useState(null);
  const [eventRightAd, setEventRightAd] = useState(null);
  const [homePageAd2, sethomePageAd2] = useState(null);
  const [homePageSlider, sethomePageSlider] = useState(null);
  const [sliderRow, setSliderRow] = useState(null);
  const [bannerCollection, setBannerCollection] = useState(null);
  const [bannerCollection2, setBannerCollection2] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.innerWidth < 767) {
      setBanner(true);
    } else {
      setBanner(false);
    }
    getBannerCollection().then((res) => setBannerCollection(res));
    getBannerCollection2().then((res) => setBannerCollection2(res));
    getBannerImage().then((res) => {
      setBannerImage(res);
    });
    getFullWidthBannerAd().then((res) => {
      setFullWidthAd(res);
    });
    getEvents().then((res) => {
      setEvents(res);
    });
    getNews().then((res) => setNews(res));
    getBannerHeroImage().then((res) => setHeroImage(res));
    getQuickLinkLeft().then((res) => setQuickLinkLeft(res));
    getQuickLinkRight().then((res) => setQuickLinkRight(res));
    getEventAdRight().then((res) => setEventRightAd(res));
    getHomePageFullLengthAd2().then((res) => sethomePageAd2(res));
    getHomePageSlider().then((res) => {
      if (res.length > 3) {
        setSliderRow(Math.ceil(res.length / 4));
      } else {
        setSliderRow(parseInt(1));
      }
      sethomePageSlider(res);
    });
  }, []);

  const getDateElement = (date, type) => {
    const data = dateToTitleFormat(date, "DD MMM, yyyy");
    if (type === "date" && data !== "TBD") {
      return data.split(" ")[0];
    } else {
      return data.split(" ")[1] + " " + data.split(" ")[2];
    }
  };

  let sectionStyle;
  if (heroImage) {
    sectionStyle = {
      backgroundImage:
        "url(" +
        configuration.strapiURL +
        heroImage.image[0].formats.large.url +
        ")"
    };
  }

  const navigateToLink = (url) => {
    window.open(
      url,
      "_blank" // <- This is what makes it open in a new window.
    );
  };

  const readMore = (item, type) => {
    let title = item.title
      ? item.title
      : item.Name
        ? item.Name
        : item.eventname
          ? item.eventname
          : "asme-article";
    history.push({
      pathname: `/article/${type}/${item.id}/${encodeURI(title)}`,
      state: { item }
    });
  };

  const readMoreFeaturedMember = (item) => {
    history.push({
      pathname: `/article/feature-member/${item.id}/${encodeURI(item.Name)}`,
      state: { item }
    });
  };

  return (
    <>
      <Container fluid className="ad-HomePagebanner-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Homepage | ASME </title>
          <link rel="canonical" href={window.location.origin} />
          <script type="text/javascript">
            {`!function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '5216654018407133');
                    fbq('track', 'PageView');
                    fbq('track', 'ViewContent');`}
          </script>
          <noscript>
            {`<img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=5216654018407133&ev=PageView&noscript=1"
                    />`}
          </noscript>
        </Helmet>
        {displayBanner &&
          banner &&
          banner.desktopbanner &&
          banner.desktopbanner[0].formats && (
            <>
              <img
                src={configuration.strapiURL + banner.desktopbanner[0].url}
                width="100%"
                alt="advertisement banner"
              />
              <div
                onClick={(e) => setBannerDisplay(false)}
                className="close-banner-homepage"
              >
                Close X
              </div>
            </>
          )}
      </Container>
      {banner && (
        <Modal
          show={adBannerMobile}
          className="ad-mobile-popup"
          onHide={(e) => setBanner(false)}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <img
              src={
                configuration.strapiURL +
                banner.mobilebanner[0].formats.small.url
              }
              width="100%"
              alt="mobile"
            />
          </Modal.Body>
        </Modal>
      )}
      <Container fluid className="hero-page" style={sectionStyle}>
        <Container className="hero-image-title">
          <h1>{heroImage ? heroImage.header : ""}</h1>
          <div className="subtitle">{heroImage ? heroImage.Subtitle : ""}</div>
          <Button onClick={(e) => history.push("/aboutus")}>Learn More</Button>
        </Container>
      </Container>
      <Container fluid>
        <Container>
          <Row>
            <Col>
              {bannerImage &&
                bannerImage.image &&
                bannerImage.image.formats &&
                bannerImage.image.formats.large ? (
                <img
                  onClick={(e) => navigateToLink(bannerImage.url)}
                  src={configuration.strapiURL + bannerImage.image.url}
                  width="100%"
                  className="banner-img-alt cursor-pointer"
                  alt="asme"
                />
              ) : bannerImage &&
                bannerImage.image &&
                bannerImage.image.formats &&
                bannerImage.image.formats.medium ? (
                <img
                  onClick={(e) => navigateToLink(bannerImage.url)}
                  src={configuration.strapiURL + bannerImage.image.url}
                  width="100%"
                  className="banner-img-alt cursor-pointer"
                  alt="asme"
                />
              ) : bannerImage &&
                bannerImage.image &&
                bannerImage.image.formats &&
                bannerImage.image.formats.small ? (
                <img
                  onClick={(e) => navigateToLink(bannerImage.url)}
                  src={configuration.strapiURL + bannerImage.image.url}
                  width="100%"
                  className="banner-img-alt cursor-pointer"
                  alt="asme"
                />
              ) : null}
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="hp-newsroom" fluid>
        <Container>
          <Row>
            <Col md={3}>
              <h1>News and Publications </h1>
              <div className="hp-newsroom-sub">View all latest reads</div>
              <Button
                className="view-more-button"
                onClick={(e) => history.push("/newsroom")}
              >
                View More
              </Button>
            </Col>
            {news &&
              news.length > 0 &&
              news.map((item) => {
                return (
                  <Col md={3}>
                    <Card className="event-card">
                      {/* Event tiles Image */}
                      {item &&
                        item.tileImage &&
                        item.tileImage[0].formats &&
                        item.tileImage[0].formats.small && (
                          <Card.Img
                            variant="top"
                            src={
                              configuration.strapiURL +
                              item.tileImage[0].formats.small.url
                            }
                          />
                        )}
                      <div className="newsroom-date">
                        {dateToTitleFormat(item.date, "MMM DD, yyyy")}
                      </div>
                      <Card.Body>
                        <Card.Text>
                          <div className="event-title">{item.title}</div>
                        </Card.Text>
                        <div
                          className="psa-readmore"
                          onClick={(e) => readMore(item, "newsroom")}
                        >
                          Read More
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}
          </Row>
          <Row className="anouncement-header mobile-view">
            <Col md={12} onClick={(e) => history.push("newsroom")}>
              View More<FaAngleDown></FaAngleDown>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="hp-advertisement-cont">
        <Container>
          <Row>
            <Col md={12}>
              <Carousel className="web-view" interval={8000}>
                {bannerCollection &&
                  bannerCollection != null &&
                  bannerCollection
                  ? bannerCollection.map((value) => {
                    return (
                      <Carousel.Item>
                        <Row>
                          <Col
                            className="cursor-pointer"
                            onClick={(e) => navigateToLink(value.url)}
                            md={12}
                          >
                            <img
                              onClick={(e) => navigateToLink(value.url)}
                              src={configuration.strapiURL + value.image.url}
                              width="100%"
                              alt="Annoucment"
                            ></img>
                          </Col>
                        </Row>
                      </Carousel.Item>
                    );
                  })
                  : null}
              </Carousel>
              <Carousel className="mobile-view">
                {bannerCollection &&
                  bannerCollection != null &&
                  bannerCollection
                  ? bannerCollection.map((value) => {
                    return (
                      <Carousel.Item>
                        <Row>
                          <Col
                            className="cursor-pointer"
                            onClick={(e) => navigateToLink(value.url)}
                            md={12}
                          >
                            {value && value.mobileImage ? (
                              <img
                                onClick={(e) => navigateToLink(value.url)}
                                src={
                                  configuration.strapiURL +
                                  value.mobileImage.url
                                }
                                width="100%"
                                alt="Annoucment"
                              ></img>
                            ) : null}
                          </Col>
                        </Row>
                      </Carousel.Item>
                    );
                  })
                  : null}
              </Carousel>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid>
        <Container>
          <Row className="hp-feature">
            <Col md={3}>
              <Row>
                <Col md={12} className="hp-feature-img">
                  <img src={Bag} width="100%" alt="bag-icon"></img>
                </Col>
                <Col md={12} className="hp-feature-text">
                  Not-for-profit organization established in 1986
                </Col>
              </Row>
            </Col>
            <Col md={3}>
              <Row>
                <Col md={12} className="hp-feature-img">
                  <img src={Book} width="100%" alt="book-icon"></img>
                </Col>
                <Col md={12} className="hp-feature-text">
                  Wide ranging services and Programme
                </Col>
              </Row>
            </Col>
            <Col md={3}>
              <Row>
                <Col md={12} className="hp-feature-img">
                  <img
                    src={Badge}
                    width="100%"
                    className="badgeImg"
                    alt="badge icon"
                  ></img>
                </Col>
                <Col md={12} className="hp-feature-text">
                  Champion of a pro-enterprise Singapore
                </Col>
              </Row>
            </Col>
            <Col md={3}>
              <Row>
                <Col md={12} className="hp-feature-img">
                  <img
                    src={People}
                    width="100%"
                    className="people"
                    alt="people icon"
                  ></img>
                </Col>
                <Col md={12} className="hp-feature-text">
                  Business Association for Entrepreneurs, by Entrepreneurs
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col md={8}></Col>
            <Col md={8}></Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="homepage-accountment hp-upcomingevents">
        <Row>
          <Col md={8}>
            <Container>
              <Row>
                <Col md={9}>
                  <h3 className="upcoming-event-mobile">Upcoming Events</h3>
                </Col>
                <Col
                  md={3}
                  className="hp-class-seemore web-view"
                  onClick={(e) => history.push("/events")}
                >
                  See All Events <FaAngleRight></FaAngleRight>
                </Col>
              </Row>
              <Row>
                {events &&
                  events.length > 0 &&
                  events.map((items) => {
                    return (
                      <Col md={6} className="event-container">
                        <Card
                          onClick={(e) => readMore(items, "event")}
                          className="event-card"
                        >
                          {items &&
                            items.tileImage &&
                            items.tileImage[0].formats &&
                            items.tileImage[0].formats.small && (
                              <Card.Img
                                variant="top"
                                src={
                                  configuration.strapiURL +
                                  items.tileImage[0].formats.small.url
                                }
                              />
                            )}
                          <div className="event-date">
                            <div className="event-month-int">
                              {getDateElement(items.date, "date")}
                            </div>
                            <div className="event-month">
                              {getDateElement(items.date)}
                            </div>
                          </div>
                          <Card.Body>
                            <Card.Title>{items.Eventtype}</Card.Title>
                            <Card.Text>
                              <div className="event-title">
                                {items.eventname}
                              </div>
                              <div className="event-details">
                                <FaRegClock></FaRegClock>{" "}
                                {dateToTitleFormat(items.date, "MMM DD, yyyy")}{" "}
                                @ {items.time}
                              </div>
                              <div className="event-details">
                                <FaMapMarker></FaMapMarker> {items.address}
                              </div>
                            </Card.Text>
                            <Button
                              variant="primary"
                              onClick={(e) => readMore(items, "event")}
                            >
                              More Details
                            </Button>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
              </Row>
              <Row className="anouncement-header mobile-view">
                <Col md={12} onClick={(e) => history.push("/events")}>
                  View More <FaAngleDown></FaAngleDown>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col md={4}>
            <Container className="right-ad-container">
              {eventRightAd && eventRightAd.image && eventRightAd.image.url ? (
                <img
                  onClick={(e) => navigateToLink(eventRightAd.Url)}
                  src={configuration.strapiURL + eventRightAd.image.url}
                  width="350px"
                  height="534px"
                  alt="upcoming events"
                  className="hp-upcomingevent-ad"
                ></img>
              ) : eventRightAd &&
                eventRightAd.image &&
                eventRightAd.image.formats &&
                eventRightAd.image.formats.thumbnail ? (
                <img
                  onClick={(e) => navigateToLink(eventRightAd.Url)}
                  src={
                    configuration.strapiURL +
                    eventRightAd.image.formats.thumbnail.url
                  }
                  width="350px"
                  height="534px"
                  alt="upcoming events"
                  className="hp-upcomingevent-ad"
                ></img>
              ) : null}
            </Container>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="hp-quick-link">
          <Col md={4}>
            <h1>Resources & Promotions</h1>
            <div>
              ASME provides a wide array of services and advertising
              opportunities for SMEs
            </div>
          </Col>
          <Col md={4} className="white">
            {quickLinkLeft &&
              quickLinkLeft.length > 0 &&
              quickLinkLeft.map((item) => {
                return (
                  <Col md={12}>
                    <div
                      onClick={(e) => navigateToLink(item.url)}
                      className="footer-quick-sub"
                    >
                      <FaAngleRight /> {item.title}
                    </div>
                  </Col>
                );
              })}
          </Col>
          <Col md={4} className="red">
            {quickLinkRight &&
              quickLinkRight.length > 0 &&
              quickLinkRight.map((item) => {
                return (
                  <Col md={12}>
                    <div
                      onClick={(e) => navigateToLink(item.url)}
                      className="footer-quick-sub"
                    >
                      <FaAngleRight /> {item.title}
                    </div>
                  </Col>
                );
              })}
          </Col>
        </Row>
      </Container>
      <Container fluid className="Ads-cleance-container">
        <div className="clearance"></div>
        <Container>
          <Carousel className="web-view">
            {bannerCollection2 && bannerCollection2 != null && bannerCollection2
              ? bannerCollection2.map((value) => {
                return (
                  <Carousel.Item>
                    <Row>
                      <Col
                        className="cursor-pointer"
                        onClick={(e) => navigateToLink(value.url)}
                        md={12}
                      >
                        <img
                          onClick={(e) => navigateToLink(value.url)}
                          src={configuration.strapiURL + value.image.url}
                          width="100%"
                          alt="Annoucment"
                        ></img>
                      </Col>
                    </Row>
                  </Carousel.Item>
                );
              })
              : null}
          </Carousel>
          <Carousel className="mobile-view">
            {bannerCollection2 && bannerCollection2 != null && bannerCollection2
              ? bannerCollection2.map((value) => {
                return (
                  <Carousel.Item>
                    <Row>
                      <Col
                        className="cursor-pointer"
                        onClick={(e) => navigateToLink(value.url)}
                        md={12}
                      >
                        {value && value.mobile ? (
                          <img
                            onClick={(e) => navigateToLink(value.url)}
                            src={configuration.strapiURL + value.mobile.url}
                            width="100%"
                            alt="Annoucment"
                          ></img>
                        ) : null}
                      </Col>
                    </Row>
                  </Carousel.Item>
                );
              })
              : null}
          </Carousel>
        </Container>
      </Container>
      <Container fluid className="homepage-member-curosal">
        <Container className="homepage-member-header">
          <h2>Featured Members</h2>
        </Container>
        <Carousel className="web-view">
          {sliderRow && homePageSlider != null && homePageSlider
            ? sliders(sliderRow, homePageSlider, readMoreFeaturedMember)
            : null}
        </Carousel>
        {/* Mobile Crousal */}
        <Carousel className="mobile-view">
          {sliderRow && homePageSlider != null && homePageSlider
            ? homePageSlider.map((value) => {
              return (
                <Carousel.Item>
                  <Row>
                    <Col
                      onClick={(e) => readMoreFeaturedMember(value)}
                      md={3}
                      className="member-highlight"
                    >
                      {value &&
                        value.tileimage &&
                        value.tileimage[0].formats &&
                        value.tileimage[0].formats.medium ? (
                        <img
                          src={
                            configuration.strapiURL +
                            value.tileimage[0].formats.medium.url
                          }
                          width="100%"
                          height="100%"
                          alt="Annoucment"
                        />
                      ) : value &&
                        value.tileimage &&
                        value.tileimage[0].formats &&
                        value.tileimage[0].formats.small ? (
                        <img
                          src={
                            configuration.strapiURL +
                            value.tileimage[0].formats.small.url
                          }
                          width="100%"
                          height="100%"
                          alt="Annoucment"
                        />
                      ) : value &&
                        value.tileimage &&
                        value.tileimage[0].formats &&
                        value.tileimage[0].formats.thumbnail ? (
                        <img
                          src={
                            configuration.strapiURL +
                            value.tileimage[0].formats.thumbnail.url
                          }
                          width="100%"
                          height="100%"
                          alt="Annoucment"
                        />
                      ) : null}
                      <div className="hp-highlight-member-detail">
                        <div className="member-name">{value.Name}</div>
                        <div className="member-designation">
                          {value.title}
                        </div>
                        <div className="member-org">{value.organisation}</div>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
              );
            })
            : null}
        </Carousel>
      </Container>
    </>
  );
}
