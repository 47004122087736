import React, { useEffect } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import PmaxLogo from '../../asset/icons/pmaxlogo.jpg';
import HomePageHero from '../../asset/pmax/home-hero.png';
import IconLocation from '../../asset/icons/icon-location.png';
import IconPhone from '../../asset/icons/icon-phone.png';
import IconMail from '../../asset/icons/icon-mail.png';
import ImageFacebook from '../../asset/icons/facebook.png';
import ImageInstagram from '../../asset/icons/instagram.png';
import NanyLogo from '../../asset/pmax/spring.png';
import WingsLogo from '../../asset/pmax/Justlogin.png';
import asmeLogo from '../../asset/icons/asme-logo.png';
import WSGLogo from '../../asset/icons/wsg.png';
import ssgLogo from '../../asset/icons/ssg.jpg';
import HelixLogo from '../../asset/pmax/itaz.png';
import imagePeople from '../../asset/pmax/People.png';
import imageTeam from '../../asset/pmax/team.png';
import mileStone1 from '../../asset/pmax/milestone1.png';
import mileStone2 from '../../asset/pmax/milestone2.png';
import mileStone3 from '../../asset/pmax/milestone3.png';
import imgTraining from '../../asset/pmax/img-training.png';
import { Helmet } from 'react-helmet';
export default function PmaxHomePage() {
    useEffect(()=> {
        window.scrollTo(0,0);
    }, [])
    const onclickNav = (type) => {
        let url = type ? window.location.origin + "/registeryourteam.html" : window.location.origin + "/accesstemplates.html";
        window.open(
            url,
            '_blank' // <- This is what makes it open in a new window.
          );
    }
    const navigateToSocialMedia = (media) => {
        if (media === "fb") {
            window.open(
                "https://facebook.com//SG.ASME",
                '_blank' // <- This is what makes it open in a new window.
              );
        } else {
            window.open(
                "https://instagram.com/asmesg",
                '_blank' // <- This is what makes it open in a new window.
              );
        }
    }
    return (<>
        <Helmet>
            <script type="text/javascript">
            {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window,document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '5216654018407133');
            fbq('track', 'PageView');`}
            </script>
            <noscript>
            {`<img height="1" width="1"
            src="https://www.facebook.com/tr?id=5216654018407133&ev=PageView
            &noscript=1" alt="facebook"/>`}
            </noscript>
        </Helmet>
        <Container fluid>
            <div className="top-bar">
                <img src={PmaxLogo} height="40px" alt="pmax">
                </img>
            </div>
        </Container>
        <Container fluid className="hero-section-pax homepage">
            <Row>
                <Col md={6} className="handbook-hero-img">
                    <img src={HomePageHero} width="70%" alt="pmax">
                    </img>
                </Col>
                <Col md={6} className="hero-section-desc">
                    <h1>
                        It's Like a Foundation to Set Your Team for Success
                    </h1>
                    <h4>
                        Professional Training for Newly Formed Teams of High Achievers.
                    </h4>
                    <Button onClick={(e) => onclickNav(true)} className="hero-image-cta" varient="danger">REGISTER YOUR TEAM</Button>
                </Col>
            </Row>
        </Container>
        <Container fluid className="pmax-payslip-desc home-page-section">
            <Container>
                <Row>
                    <Col md={12}>
                        <h6>Engage Your Employees to See High Performance and Impact</h6>
                    </Col>
                    <Col md={12}>
                        <div className="bottom-desc">
                            Engaged employees perform better and stay longer and differentiate you from your competitors. But you can't improve engagement with team building activities alone.
                            <br />
                            <br />
                            Through training, goal setting, and mentorship (the right kind), we help you raise engagement levels — and improve your business outcomes.
                            <br />
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
        <Container fluid className="pmax-employee-handbook-hero">
            <Row>
                <Col md={6} className="handbook-hero-img">
                    <img src={imagePeople} alt="people">
                    </img>
                </Col>
                <Col md={6} className="">
                    <h1>
                        SMEs Get Subsidies & Grants
                    </h1>
                    <h4>
                        For Hiring SMEs (P-Max & P-Max for Older Workers)
                    </h4>
                    <ul>
                        <li>Get 90% course fee subsidy for trainings and workshops</li>
                        <li>Receive a one-time $5,000 Assistance Grant or $10,000 Assistance Grant upon completion</li>
                    </ul>
                    <h4>
                        For Non-Hiring SMEs (P-Max Lite)
                    </h4>
                    <ul>
                        <li>Enjoy up to 90% course fee subsidy for workshops</li>
                    </ul>
                </Col>
            </Row>
        </Container>
        <Container fluid className="pmax-slip-benefit pmax-home-detail">
            <Container>
                <Row>
                    <Col md={12}>
                        <h6>Pmax is perfect for you if </h6>
                        <ul>
                            <li>You have a small team of 2-3 employees who need to be developed</li>
                            <li>You just hired staff and need the right HR concepts to help them get started</li>
                            <li>You need to replicate yourself to make your business more productive</li>
                            <li>You want to measure employee performance and reward the right person in the right way</li>
                            <li>You are tired of ineffective trainings and frameworks that create more trouble than its worth</li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="section-below-detail">
                        <div>
                            We have a limited number of slots available and request that only applicants who are serious about business growth apply.
                    <br />
                            <br />
                    To register for training sessions, click below and choose the best time for you.
                    </div>
                        <Button onClick={(e) => onclickNav(true)} className="hero-image-cta" varient="danger">REGISTER YOUR TEAM</Button>
                    </Col>
                    <Col md={6} className="handbook-hero-img">
                        <img src={imageTeam} alt="team">
                        </img>
                    </Col>
                </Row>
            </Container>
        </Container>
        <Container fluid className="pmax-bottom training-desc">
            <Container>
                <div className="bottom-desc">
                    <h1>The Training will allow you to</h1>
                    <Row className="benefits-handbook">
                        <Col md={4}>
                            <div>
                                <img src={mileStone1} width="60%" alt="milestone" />
                                <div className="training-title-sub">
                                    <h3>Gain Clarity On Milestones </h3>
                                    <div>What does success look like to you? Does your team have the same vision? </div><br />
                                    <div>P-MAX training will help you lay the groundwork for evolving from business operator to business owner with less dependence on you.</div>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div>
                                <img src={mileStone2} width="60%" alt="milestone" />
                                <div className="training-title-sub">
                                    <h3>Create Success Criteria<br /></h3><br />
                                    <div>What gets measured gets managed. Our trainers will help your team set tailored high-performance metrics in order to maximise their potential. That way, you can sleep easy, knowing your business is in control and powering ahead.</div><br />
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div>
                                <img src={mileStone3} width="60%" alt="milestone" />
                                <div className="training-title-sub">
                                    <h3>Be The Leader Your Team Needs </h3>
                                    <div>Very often, business owners are accidental leaders. And that's not ok. When someone joins your company, they need to be supported and groomed to be the best versions of themselves. With professional training, you can make a bigger impact on their lives and in the world.</div><br />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Container>
        <Container fluid className="how-it-works">
            <Container>
                <h1>How It Works</h1>
                <Row>
                    <Col md={6} className="handbook-hero-img">
                        <img src={imgTraining} alt="people">
                        </img>
                    </Col>
                    <Col md={6} className="">
                        <ul>
                            <li>
                                <div className="display-flex">
                                    <div className="number">1</div>
                                    <h3>Train & Learn</h3>
                                </div>
                                <div className="follow-up">
                                    Build up your organisation's human capital capabilities and maximise their potential.
                            </div>
                            </li>
                            <li>
                                <div className="display-flex">
                                    <div className="number">2</div>
                                    <h3>Track to Improve</h3>
                                </div>
                                <div className="follow-up">
                                    Boosting your workforce performance is easy with metrics, assessments and reviews.
                            </div>
                            </li>
                            <li>
                                <div className="display-flex">
                                    <div className="number">3</div>
                                    <h3>Get Funding </h3>
                                </div>
                                <div className="follow-up">
                                    Enjoy up to 90% course fee funding, plus up to $10,000 of grants for your staff and supervisors
                            </div>
                            </li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                    <Button onClick={(e) => onclickNav(true)} className="hero-image-cta" varient="danger">REGISTER YOUR TEAM</Button>
                    </Col>
                </Row>
            </Container>
        </Container>
        <Container fluid className="pmax-homepage-benefit pmax-homepage-clients">
            <Container>
                <h1>Successful Companies Have Trained With Us</h1>
                <Row>
                    <Col md={4}>
                        <img src={NanyLogo} alt="clients" width="70%" />
                    </Col>
                    <Col md={4}>
                        <img src={WingsLogo} alt="clients" width="70%" />
                    </Col>
                    <Col md={4}>
                        <img src={HelixLogo} alt="clients" width="50%" />
                    </Col>
                </Row>
            </Container>
        </Container>
        <Container fluid className="pmax-bottom pax-company">
            <Container>
                <h1>We’ve helped them. We Can Help You Too</h1>
                <div><Button onClick={(e) => onclickNav(true)} className="hero-image-cta margin-top-50" varient="danger">REGISTER YOUR TEAM</Button></div>
            </Container>
        </Container>
        <Container fluid className="pmax-homepage-benefit pmax-homepage-clients">
            <Container>
                <h1>In Collaboration With</h1>
                <Row>
                    <Col md={4}>
                        <img src={asmeLogo} alt="clients" width="60%" />
                    </Col>
                    <Col md={4}>
                        <img src={WSGLogo} alt="clients" width="60%" />
                    </Col>
                    <Col md={4}>
                        <img src={ssgLogo} alt="clients" width="60%" />
                    </Col>
                </Row>
            </Container>
        </Container>
        <Container fluid className="pmax-homepage-benefit">
            <Container>
                <h1>UNLEASH THE POWER OF YOUR NEW HIRES TODAY</h1>
                <div><Button onClick={(e) => onclickNav(true)} className="hero-image-cta bottom-cta" varient="danger">REGISTER YOUR TEAM</Button></div>
            </Container>
        </Container>
        <Container fluid className="pmax-footer">
            <Container>
                <Row>
                    <Col md={4}>
                        <img src={PmaxLogo} alt="pmax" width="80%" className="footer-logo" />
                    </Col>
                    <Col md={4} className="footer-contact">
                        <div className="display-flex">
                            <img src={IconLocation} height="24px" alt="Location icon" />
                            <div>167, <br />
                            Jalan Bukit Merah,<br />
                            Singapore 150167<br /></div>
                        </div>
                        <div className="display-flex">
                            <img src={IconPhone} height="24px" alt="Location icon" />
                            <div>6513-0388</div>
                        </div>
                        <div className="display-flex">
                            <img src={IconMail} height="24px" alt="Location icon" />
                            <div>pmax@asme.org.sg</div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <Row>
                            <Col md={4}>
                                <img onClick={(e) => navigateToSocialMedia("fb")} src={ImageFacebook} alt="facebook" className="img-adjust" width="100%" />
                            </Col>
                            <Col md={4}>
                                <img onClick={(e) => navigateToSocialMedia()} src={ImageInstagram} alt="instagram" className="img-adjust" width="100%" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Container>
        <Container fluid className="pmax-home-footer">
            <Row>
                <Col md={12}>
                    <h6>Copyright</h6>
                </Col>
                <Col md={12} className="footer-privacy-policy">
                    <h6><div>Privacy Policy {" "}</div> | <div>{" "}Terms of Services</div></h6>
                </Col>
            </Row>
        </Container>
    </>
    )
}
