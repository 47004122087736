import React, { useEffect, useState } from 'react'
import { Breadcrumb, Col, Container, Row, Card, Button } from 'react-bootstrap';
import '../../stylesheets/events.css';
import NewsRoomBanner from '../../asset/banner/newsroom-banner.png';
import { NavLink } from 'react-router-dom';
import { dateToTitleFormat } from '../../utils/DateUtils';
import { Helmet } from 'react-helmet';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Box, makeStyles } from '@material-ui/core';
import { FaAngleDown, FaFilter, FaSearch } from 'react-icons/fa';
import axios from 'axios';
import Loading from '../Loading';
import { configuration } from '../../config/config';
import ReactMarkdown from 'react-markdown';
import heartOutlined from "../../asset/icons/heart-outline.svg"
import heartFilled from "../../asset/icons/heart-fill.svg"
import { getFavouriteArticle, setFavouriteArticle } from '../../utils/constent';
import ReactGA from 'react-ga4';


const getArticles = (type, category, resources_id) => {
    let url = `/resource-center-articles?_sort=date:DESC`
    if (type) {
        url = `${url}&type=${type}`
    }
    if (category) {
        url = `${url}&category=${category}`
    }
    url = `${url}&resource_center=${resources_id}`

    return axios.get(url)
        .then(res => {
            return res.data;
        })
}

const getResources = (id) => {
    return axios.get(`/resource-centers?id=${id}`)
        .then(res => {
            return res.data;
        })
}
export default function Articles(props) {
    const useStyles = makeStyles((theme) => ({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: "100%",
        },
    }));

    const { id, type, category } = props.match.params
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true)
    const [resources, setResources] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0)
        if (props.location && props.location.data) {
            setResources(props.location.data)
        } else if (props && props.match && props.match.params) {
            getResources(props.match.params.id).then(res => {
                setResources(res[0]);
            })
        }
        getArticles(type, category, id).then(res => {
            setData(res);
            setLoading(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);
    const filterData = data.filter(e => (!search || (e.title.toLowerCase().includes(search.toLowerCase()))))

    const readMore = (item) => {
        props.history.push({ pathname: `/resourcecenter/${id}/${type}/${category}/${item.id}`, state: { data: item } });
    }
    return (loading ? <Loading></Loading> :
        (data && data.length > 0 ?
            <Container fluid className="Events-container article-container">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Article | ASME </title>
                    <link rel="canonical" href={window.location.origin} />
                    <script type="text/javascript">
                        {`!function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '5216654018407133');
                    fbq('track', 'PageView');
                    fbq('track', 'ViewContent');`}
                    </script>
                    <noscript>
                        {`<img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=5216654018407133&ev=PageView&noscript=1"
                    />`}
                    </noscript>
                </Helmet>
                <Container fluid className="banner-container">
                    <img src={NewsRoomBanner} alt="Event Banner" width="100%" className="banner-img"></img>
                    <h1>Articles</h1>
                </Container>
                <Container>
                    <Row>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <NavLink to="/">Home</NavLink>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <NavLink to="/resourcecenter" onClick={(e) => e.preventDefault()}>Resource Center</NavLink>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <NavLink className="text-capitalize" to={"/resourcecenter/" + resources?.id} >{resources?.title}</NavLink>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <NavLink className="text-capitalize" to={"/resourcecenter/" + resources?.id + "/" + type} >{type}</NavLink>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <NavLink className="text-capitalize" to={"/resourcecenter/" + resources?.id + "/" + type + "/" + category} onClick={(e) => e.preventDefault()} >{category}</NavLink>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Row>
                    <Row>
                        <h2 className="news-room-header">Articles</h2>
                    </Row>
                </Container>
                <Container className="member-container">
                    <Row className='justify-content-end'>
                        <Col md={8} xs={12} className="article-search ">
                            <Box className='pr-xl-2'>
                                <TextField
                                    placeholder='search'
                                    id="date"
                                    name="search"
                                    variant='outlined'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment>
                                                <IconButton>
                                                    <FaSearch size="16" />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    onChange={(e) => {
                                        setSearch(e.target.value)
                                    }}
                                    className={classes.textField}
                                />
                            </Box>
                        </Col>
                        <Col md={8} xs={12} >
                            <Box display="flex" justifyContent="flex-end" alignItems="center" className='article-filter pr-xl-2'>
                                <FaFilter size="10" />
                                <Button
                                    id="fade-button"
                                    aria-haspopup="true"
                                    className="p-1">
                                    Filter <FaAngleDown size="14" />
                                </Button>
                            </Box>
                        </Col>
                    </Row>

                    {filterData && filterData.length === 0 ?
                        <Row><Col md={12}>No Data Found</Col></Row>
                        : <Row>
                            {filterData.map((value, index) => {
                                return <ArticleItem value={value} index={index} key={index} readMore={readMore} />
                            })}
                        </Row>}
                </Container>
            </Container> :
            <Container fluid className="Events-container article-container">
                <Container fluid className="banner-container">
                    <img src={NewsRoomBanner} alt="Event Banner" width="100%" className="banner-img"></img>
                    <h1>Articles</h1>
                </Container>
                <Container>
                    <Row>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <NavLink to="/">Home</NavLink>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <NavLink to="/resourcecenter" onClick={(e) => e.preventDefault()}>Resource Center</NavLink>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <NavLink className="text-capitalize" to={"/resourcecenter/" + resources?.id} >{resources?.title}</NavLink>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <NavLink className="text-capitalize" to={"/resourcecenter/" + resources?.id + "/" + type} >{type}</NavLink>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <NavLink className="text-capitalize" to={"/resourcecenter/" + resources?.id + "/" + type + "/" + category} onClick={(e) => e.preventDefault()} >{category}</NavLink>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Row>
                    <Row>
                        <h2 className="news-room-header">Articles</h2>
                    </Row>
                </Container>

                <Container className="member-container py-xl-4 py-2">
                    <h1 className='text-center'>No Articles</h1>
                </Container>
            </Container>
        ))
}


const ArticleItem = ({ index, value, readMore }) => {
    const [favItem, setFavItem] = useState("")

    useEffect(() => {
        const favourite = getFavouriteArticle(value.id)
        setFavItem(!(!favourite))
    }, [])

    const addToFavourite = (id) => {
        setFavItem(true)
        setFavouriteArticle(id, "true")
        ReactGA.event({ category: "FavouriteArticle", action: value.title, label: value.title, value: 1 })
        // ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: value.title });
        ReactGA.send({ hitType: "event", page: window.location.pathname, title: value.title });
        ReactGA.send({ hitType: "interest", page: window.location.pathname, title: value.title });

    }

    const deleteFromFavourite = (id) => {
        setFavouriteArticle(id, "")
        setFavItem(false)
    }

    return <Col md={4} className="event-container" key={index}>
        <Card className="event-card">
            {value && value.image && value.image.formats && value.image.formats.large ? <Card.Img
                onClick={(e) => readMore(value)}
                variant="top" src={configuration.strapiURL + value.image.formats.large.url} /> :
                value && value.image && value.image.formats && value.image.formats.medium ? <Card.Img
                    onClick={(e) => readMore(value)}
                    variant="top" src={configuration.strapiURL + value.image.formats.medium.url} /> :
                    value && value.image && value.image.formats && value.image.formats.small ? <Card.Img
                        onClick={(e) => readMore(value)}
                        variant="top" src={configuration.strapiURL + value.image.formats.small.url} /> : null}
            <div className="newsroom-date">{dateToTitleFormat(value.date, 'MMM DD, yyyy')}</div>

            <Card.Body className='position-relative'>
                {!favItem ?
                    <img onClick={() => addToFavourite(value.id)} src={heartOutlined} alt={value.title} height={20} width={20} className="fav_icon" /> :
                    <img onClick={() => deleteFromFavourite(value.id)} src={heartFilled} alt={value.title} height={20} width={20} className="fav_icon" />}
                <Card.Text>
                    <div className="event-title text-capitalize"
                        onClick={(e) => readMore(value)}
                    >{value.title || "Title"}</div>
                </Card.Text>
                <ReactMarkdown>{value.shortDescription}</ReactMarkdown>
                <Button variant="primary" className="newsroom-button text-capitalize">{value.type || "Type"}</Button>
                <div className="psa-readmore"
                    onClick={() => readMore(value)}>Read More</div>
            </Card.Body>
        </Card>
    </Col>
}