import React, { Component } from 'react'
import { Accordion, Card, Col, Container, Nav, Navbar, Row } from 'react-bootstrap'
import '../stylesheets/common.css';
import {
    Switch,
    Route,
    NavLink
} from "react-router-dom";
import Logo from './../asset/icons/asme-logo.png';
import HomePage from './HomePage';
import Footer from './Footer';
import Events from './Events';
import NewsRoom from './NewsRoom';
import ResourceCenter from './resourcesCenter/ResourceCenter';
import AboutUs from './AboutUs';
import PublicServiceAnnouncement from './PublicServiceAnnouncement';
import FeaturedMembers from './FeaturedMember';
import { FaClock, FaPhoneAlt, FaAngleRight, FaAngleDown } from 'react-icons/fa';
import Article from './Article';
import axios from 'axios';
import $ from 'jquery';
import PrivacyPolicy from './PrivacyPolicy';
import PrivacyStatement from './PrivacyStatement';
import Awards from './Awards';
import Membership from './Membership';
import Error from './Error';
import Contact from './Contact';
import Articles from './resourcesCenter/Articles';
import ArticleDetails from './resourcesCenter/ArticleDetails';
import Categories from './resourcesCenter/Categories';
import Programmes from './Programmes';
const getBannerAdvertisement = () => {
    return axios.get(`/main-banner-advertisement`)
        .then(res => {
            return res.data;
        });

}
const getFooterQuickLinks = () => {
    return axios.get(`/footer-quick-links`)
        .then(res => {
            return res.data;
        });
}
const getResourceValue = () => {
    return axios.get(`/resource-centers`)
        .then(res => {
            return res.data;
        });

}
export default class Main extends Component {
    constructor() {
        super();
        this.state = {
            banner: null,
            quickLinks: null,
            resource: null,
            selected: 0
        }
        this.hideDropdown = this.hideDropdown.bind(this);
    }

    componentDidMount = async () => {
        try {
            const bannerData = await getBannerAdvertisement();
            if (bannerData) {
                this.setState({
                    banner: bannerData
                })
            }
        } catch (err) { }

        try {
            const quickLinks = await getFooterQuickLinks();
            if (quickLinks) {
                this.setState({
                    quickLinks: quickLinks
                })
            }
        } catch (err) { }

        const resource = await getResourceValue();

        if (resource) {
            this.setState({
                resource: resource
            })
        }
        $('.rsc').hover(function () {
            if (window.innerWidth > 991) {
                $('.dropdown-content').css("display", "block");
            }
        });
        $('.dropdown-content').hover(function () {
            $('.dropdown-content').css("display", "block");
        });
        $('.dropdown-content').mouseleave(function () {
            $('.dropdown-content').css("display", "none");
        });
        $('.other').hover(function () {
            $('.dropdown-content').css("display", "none");
        });
    }

    hideDropdown = () => {
        $('.dropdown-content').css("display", "none");
    }
    render() {
        return (
            <Container fluid>
                <Container className="banner">
                    <div className="banner-ph">
                        <FaPhoneAlt></FaPhoneAlt> Phone: 6513 0388
                    </div>
                    <div className="banner-hours">
                        <FaClock></FaClock> Opening Hours: Mon - Fri 9:00 am - 6:00 pm
                    </div>
                </Container>
                <Container fluid className="navbar-container">
                    <Container>
                        <Navbar expand="lg">
                            <Navbar.Brand href="/"><img src={Logo} width="60" alt="ASME Logo" /></Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="mr-auto">
                                    <NavLink exact to="/" className="other">Home</NavLink>
                                    <NavLink to="/aboutus" className="other">About Us</NavLink>
                                    <NavLink to="/programmes" className="other">Programmes</NavLink>
                                    <NavLink to="/Membership" className="other">Membership</NavLink>
                                    <NavLink to="/events" className="other">Events</NavLink>
                                    <NavLink to="/resourcecenter" onClick={(e) => e.preventDefault()} className="rsc mobile">
                                        <Accordion>
                                            <Accordion.Toggle as={Card.Header} eventKey="1" variant="link">
                                                Resource Centre <FaAngleDown></FaAngleDown>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="1"><>
                                                {this.state.resource && this.state.resource.map((res, index) => {
                                                    return <Col md={12} className="display-flex" key={index}>
                                                        <Col md={12} className="hover-title parent" onClick={(e) => this.setState({ selected: index })}> <NavLink to={{ pathname: "/resourcecenter/" + res.id, data: res }} onClick={(e) => this.hideDropdown()}> <div className={'hover-item'}> <FaAngleRight /> {res.title} </div> </NavLink></Col>
                                                    </Col>
                                                })}</>
                                            </Accordion.Collapse>
                                        </Accordion>
                                    </NavLink>
                                    <NavLink to="/resourcecenter" onClick={(e) => e.preventDefault()} className="rsc">Resource Centre
                                    </NavLink>
                                    <NavLink to="/featuredMembers" className="other">Featured Members</NavLink>
                                    <NavLink to="/Awards" className="other">Awards</NavLink>
                                    <NavLink to="/newsroom" className="other">Newsroom</NavLink>
                                    {/* <NavLink to="/publicserviceannouncement" className="other">Public Service Announcement</NavLink> */}
                                    <NavLink to="/Contact" className="other">Contact</NavLink>
                                </Nav>
                            </Navbar.Collapse>
                            {/* <FaSearch className="nav-search"></FaSearch> */}
                        </Navbar>
                        <div className="dropdown-content">
                            <Row className="">
                                {this.state.resource && this.state.resource.map((res, index) => {
                                    return <Col md={6} className="display-flex" key={index}>
                                        <Col md={12} className="hover-title parent" onClick={(e) => this.setState({ selected: index })}> <NavLink to={{ pathname: "/resourcecenter/" + res.id, data: res }} onClick={(e) => this.hideDropdown()}> <div className={'hover-item'}> <FaAngleRight /> {res.title} </div> </NavLink></Col>
                                    </Col>
                                })}
                            </Row>
                        </div>
                    </Container>
                </Container>
                <Switch>
                    <Route
                        exact
                        path="/"
                        render={props => <HomePage banner={this.state.banner} {...props} />}
                    ></Route>
                    <Route
                        exact
                        path="/programmes"
                        render={props => <Programmes {...props} />}
                    ></Route>
                    <Route
                        exact
                        path="/events"
                        render={props => <Events {...props} />}
                    ></Route>
                    <Route
                        exact
                        path="/newsroom"
                        render={props => <NewsRoom {...props} />}
                    ></Route>

                    <Route
                        exact
                        path="/resourcecenter/:id"
                        render={props => <ResourceCenter {...props} />}
                    ></Route>
                    <Route
                        exact
                        path="/resourcecenter/:id/:type"
                        render={props => <Categories {...props} />}
                    ></Route>
                    <Route
                        exact
                        path="/resourcecenter/:id/:type/:category"
                        render={props => <Articles {...props} />}
                    ></Route>
                    <Route
                        exact
                        path="/resourcecenter/:id/:type/:category/:articleid"
                        render={props => <ArticleDetails {...props} />}
                    ></Route>
                    <Route
                        exact
                        path="/aboutus"
                        render={props => <AboutUs {...props} />}
                    ></Route>
                    <Route
                        exact
                        path="/publicserviceannouncement"
                        render={props => <PublicServiceAnnouncement {...props} />}
                    ></Route>
                    <Route
                        exact
                        path="/featuredMembers"
                        render={props => <FeaturedMembers {...props} />}
                    ></Route>
                    <Route
                        exact
                        path="/termofuse"
                        render={props => <PrivacyPolicy {...props} />}
                    ></Route>
                    <Route
                        exact
                        path="/privacystatement"
                        render={props => <PrivacyStatement {...props} />}
                    ></Route>
                    <Route
                        exact
                        path="/article/:type/:id"
                        render={props => <Article {...props} />}
                    ></Route>
                    <Route
                        exact
                        path="/article/:type/:id/:title"
                        render={props => <Article {...props} />}
                    ></Route>
                    <Route
                        exact
                        path="/Awards"
                        render={props => <Awards {...props} />}
                    ></Route>
                    <Route
                        exact
                        path="/Membership"
                        render={props => <Membership {...props} />}
                    ></Route>
                    <Route
                        exact
                        path="/signup"
                        render={props => <Membership {...props} />}
                    ></Route>
                    <Route
                        exact
                        path="/Contact"
                        render={props => <Contact {...props} />}
                    ></Route>
                    <Route
                        path="/"
                        render={props => <Error {...props} />}
                    ></Route>

                </Switch>
                <Container fluid>
                    <Footer quickLinks={this.state.quickLinks}></Footer>
                </Container>
            </Container>
        )
    }
}
