import axios from "axios";
import { useEffect, useState } from "react";
import { Breadcrumb, Container, Row, Col, Card, Accordion } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FaAngleRight } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';
import { configuration } from "../config/config";
import Loading from "./Loading";

const getProgrammes = () => {
    return axios.get(`/programmes`)
        .then(res => {
            return res.data;
        })
}

export default function Programmes() {
    const [programmes, setProgrammes] = useState([])
    const [loading, setLoading] = useState(true)
    const [id, setId] = useState(null)
    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        setLoading(true)
        getProgrammes()
            .then(res => {
                setProgrammes(res)
                setId(res[0].id)
                setLoading(false)
            })
    }

    const currentProgramme = programmes.find((e) => e.id === id)
    return (<Container fluid className="Events-container article-detail programmes">
        <Helmet>
            <meta charSet="utf-8" />
            <title>Programmes | ASME </title>
            <link rel="canonical" href={window.location.origin} />
            <script type="text/javascript">
                {`!function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '5216654018407133');
                    fbq('track', 'PageView');
                    fbq('track', 'ViewContent');`}
            </script>
            <noscript>
                {`<img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=5216654018407133&ev=PageView&noscript=1"
                    />`}
            </noscript>
        </Helmet>
        <Container>
            <Row>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <NavLink to="/">Home</NavLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <NavLink to="/resourcecenter" onClick={(e) => e.preventDefault()}>Programmes</NavLink>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Row>
        </Container>
        <Container className="member-container">
            {loading ? <Loading /> : (!currentProgramme ? <Row><Col md={12}>No Data Found</Col></Row> :
                <>
                    <Row className="d-none d-lg-flex">
                        <Col md={4} className="">
                            <Card className='programming-anchoring border-0 shadow-none'>
                                {programmes.map((e) => {
                                    return <div role="button" onClick={() => {
                                        setId(e.id)
                                    }} key={e.id} className={`px-xl-2 pb-xl-2 pt-xl-0 py-2  text-dark ${e.id === id ? "active-item" : ""}`}>
                                        <FaAngleRight /> {e.title}
                                    </div>
                                })}
                            </Card>
                        </Col>
                        <Col md={8} className="mt-2 mt-xl-0">
                            <div className="member-container">
                                <h4 className='text-capitalize mb-0 pb-0 px-0 programme-title' style={{ fontWeight: 600 }}>
                                    {currentProgramme?.title}
                                </h4>
                                {currentProgramme?.for && <div className="article-details pt-2 pb-0">
                                    <strong>For {currentProgramme?.for}:</strong>
                                </div>}
                                <ReactMarkdown>{currentProgramme?.information?.replaceAll("](/", `](${process.env.REACT_APP_STRAPI_URL}/`)}</ReactMarkdown>
                                {currentProgramme && currentProgramme.stories && currentProgramme.stories.length > 0 && <h3 className='mb-0 pb-2 px-0'>
                                    Success stories or reviews
                                </h3>}

                                {currentProgramme && currentProgramme.stories && currentProgramme.stories.length > 0 && <div className="stories-container">
                                    {currentProgramme.stories.map((e) => {
                                        return <div key={e.id} className="event-card card m-0">
                                            <div className="article-img">
                                                {e && e.image[0] && e.image[0].formats && e.image[0].formats.large ? <Card.Img
                                                    variant="top" src={configuration.strapiURL + e.image[0].formats.large.url} /> :
                                                    e && e.image[0] && e.image[0].formats && e.image[0].formats.medium ? <Card.Img
                                                        variant="top" src={configuration.strapiURL + e.image[0].formats.medium.url} /> :
                                                        e && e.image[0] && e.image[0].formats && e.image[0].formats.small ? <Card.Img
                                                            variant="top" src={configuration.strapiURL + e.image[0].formats.small.url} /> : null}
                                            </div>
                                            {(e.name || e.company || e.summary) && <div className='overview article_desc p-2'>
                                                {e.name && <p className="text-capitalize"><strong>Name :</strong> {e.name}</p>}
                                                {e.company && <p><strong>Company :</strong> {e.company}</p>}
                                                {e.summary && <p><strong>Summary :</strong> {e.summary}</p>}
                                            </div>}
                                        </div>
                                    })}
                                </div>}

                                {(currentProgramme.phone || currentProgramme.email || currentProgramme.website) && <div>
                                    <h3 className='text-capitalize mb-0 pb-0 px-0'>
                                        Call to action
                                    </h3>
                                    <div className='call-action py-0 overview article_desc text-dark'>
                                        {currentProgramme.phone && <a href={`tel:${currentProgramme.phone}`} className="text-dark"><p><strong>Call us: </strong>{currentProgramme.phone}</p></a>}
                                        {currentProgramme.email && <a href={`mailto:${currentProgramme.email}`} className="text-dark"><p><strong>Email us: </strong>{currentProgramme.email}</p></a>}
                                        {currentProgramme.website && <a target="blank_" href={currentProgramme.website} className="text-dark"><p><strong>Visit us:</strong>{currentProgramme.website}</p></a>}
                                    </div>
                                </div>}
                            </div>
                        </Col>
                    </Row>
                    <Row className="d-lg-none">
                        <Col md={12} className="">
                            <Card className='programming-anchoring border-0 shadow-none'>
                                {programmes.map((el) => {
                                    return <Accordion key={el.id}>
                                        <Accordion.Toggle onClick={() => setId(el.id)} as="Button" variant="link" eventKey={el.id} className="accordin-title">
                                            <div role="button" key={el.id} className={`px-xl-2 pb-xl-2 pt-xl-0 py-2  text-dark ${el.id === id ? "active-item" : ""}`}>
                                                <FaAngleRight /> {el.title}
                                            </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={el.id}>
                                            <div className="mt-2 mt-xl-0">
                                                <div className="member-container">
                                                    <h4 className='text-capitalize mb-0 pb-0 px-0 programme-title' style={{ fontWeight: 600 }}>
                                                        {el?.title}
                                                    </h4>
                                                    {el.for && <div className="article-details pt-2 pb-0">
                                                        <strong>For {el?.for}:</strong>
                                                    </div>}
                                                    <ReactMarkdown>{el?.information?.replaceAll("](/", `](${process.env.REACT_APP_STRAPI_URL}/`)}</ReactMarkdown>
                                                    {el && el.stories && el.stories.length > 0 && <h3 className='mb-0 pb-2 px-0'>
                                                        Success stories or reviews
                                                    </h3>}

                                                    {el && el.stories && el.stories.length > 0 && <div className="stories-container">
                                                        {el.stories.map((e) => {
                                                            return <div key={e.id} className="event-card card m-0">
                                                                <div className="article-img">
                                                                    {e && e.image[0] && e.image[0].formats && e.image[0].formats.large ? <Card.Img
                                                                        variant="top" src={configuration.strapiURL + e.image[0].formats.large.url} /> :
                                                                        e && e.image[0] && e.image[0].formats && e.image[0].formats.medium ? <Card.Img
                                                                            variant="top" src={configuration.strapiURL + e.image[0].formats.medium.url} /> :
                                                                            e && e.image[0] && e.image[0].formats && e.image[0].formats.small ? <Card.Img
                                                                                variant="top" src={configuration.strapiURL + e.image[0].formats.small.url} /> : null}
                                                                </div>
                                                                {(e.name || e.company || e.summary) && <div className='overview article_desc p-2'>
                                                                    {e.name && <p className="text-capitalize"><strong>Name :</strong> {e.name}</p>}
                                                                    {e.company && <p><strong>Company :</strong> {e.company}</p>}
                                                                    {e.summary && <p><strong>Summary :</strong> {e.summary}</p>}
                                                                </div>}
                                                            </div>
                                                        })}
                                                    </div>}

                                                    {(el.phone || el.email || el.website) && <div>
                                                        <h3 className='text-capitalize mb-0 pb-0 px-0'>
                                                            Call to action
                                                        </h3>
                                                        <div className='call-action py-0 overview article_desc text-dark'>
                                                            {el.phone && <a href={`tel:${el.phone}`} className="text-dark"><p><strong>Call us: </strong>{el.phone}</p></a>}
                                                            {el.email && <a href={`mailto:${el.email}`} className="text-dark"><p><strong>Email us: </strong>{el.email}</p></a>}
                                                            {el.website && <a target="blank_" href={el.website} className="text-dark"><p><strong>Visit us:</strong>{el.website}</p></a>}
                                                        </div>
                                                    </div>}
                                                </div>
                                            </div>
                                        </Accordion.Collapse>
                                    </Accordion>
                                })}
                            </Card>
                        </Col>
                    </Row>
                </>
            )}
        </Container>
    </Container>
    )
}