import React, {useState, useEffect} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Logo from '../asset/icons/asme-logo.png';
import Clock from '../asset/icons/clock.png';
import Phone from '../asset/icons/phone.png';
import Email from '../asset/icons/email.png';
import { FaAngleRight, FaFacebook, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
const EmailSubLink = () => {
    return axios.get(`/email-subscribing-url`).then(res => {return res.data});
}
export default function Footer({ quickLinks }) {
    const [emailURL, setEmailURl] = useState(null);
    useEffect(() => {
        EmailSubLink().then(res => {setEmailURl(res)})
    }, [])
    const navigateToSocialMedia = (media) => {
        if (media === "fb") {
            window.open(
                "https://facebook.com//SG.ASME",
                '_blank' // <- This is what makes it open in a new window.
              );
        } else if (media === "linkedin") {
            window.open(
                "https://www.linkedin.com/company/sgasme/",
                '_blank' // <- This is what makes it open in a new window.
              );
        } else {
            window.open(
                "https://instagram.com/asmesg",
                '_blank' // <- This is what makes it open in a new window.
              );
        }
    }
    const navigationToUrl = (url) => {
        window.open(
            url,
            '_blank' // <- This is what makes it open in a new window.
          );
    } 
    return (<>
        <Container className="footer-top">
            <Row>
                <Col md={9}>
                    <NavLink to="/aboutus" className="footer-top-link">About Us</NavLink>
                    <NavLink to="/Membership" className="footer-top-link">Membership</NavLink>
                    <NavLink to="/events" className="footer-top-link">Events</NavLink>
                    <NavLink to="/resourcecenter" className="footer-top-link">Resource Centre</NavLink>
                    <NavLink to="/featuredMembers" className="footer-top-link">Featured Members</NavLink>
                    <NavLink to="/Awards" className="footer-top-link">Awards</NavLink>
                    <NavLink to="/newsroom" className="footer-top-link">Newsroom</NavLink>
                    <NavLink to="/Contact" className="footer-top-link">Contact</NavLink>
                    {/* <NavLink to="/publicserviceannouncement" className="footer-top-link">Public Service Announcement</NavLink> */}
                </Col>
                <Col md={3}>
                    <div className="footer-top-link"> <div className="text">Connect with us: </div>
                        <FaFacebook onClick={(e) => navigateToSocialMedia("fb")}></FaFacebook>
                        <FaInstagram onClick={(e) => navigateToSocialMedia("insta")}></FaInstagram>
                        <FaLinkedinIn onClick={(e) => navigateToSocialMedia("linkedin")}></FaLinkedinIn>
                    </div>
                </Col>
            </Row>
        </Container>
        <Container fluid className="footer-main">
            <Container>
                <Row>
                    <Col md={3}>
                        <Col md={12}>
                            <img src={Logo} width="60" height="60" className="footer-logo" alt="ASME Logo" />
                        </Col>
                        <Col md={12} className="footer-subtext fotter-subtext-cont">
                            167 Jalan Bukit Merah
                            Connection One (Tower 4)
                            #03-13 Singapore 150167
                    </Col>
                        <Col md={12} className="fotter-subtext-cont hours">
                            <div className="footer-contact-info"><img src={Clock} alt="clock"></img><div className="footer-header">Opening Hours</div></div>
                            <div className="footer-subtext">Mon - Fri 9:00 am - 6:00 pm</div>
                        </Col>
                        <Col md={12} className="fotter-subtext-cont">
                            <div className="footer-contact-info"><img src={Phone} alt="phone"></img><div className="footer-header">Phone:</div></div>
                            <div className="footer-subtext">6513 0388</div>
                        </Col>
                        <Col md={12} className="fotter-subtext-cont">
                            <div className="footer-contact-info"><img alt="email" src={Email}></img><div className="footer-header">Email:</div> </div>
                            <div className="footer-subtext">resources@asme.org.sg</div>
                        </Col>
                    </Col>
                    <Col md={4}>
                        <Col md={12} className="footer-quick-link">
                            <div>Quick Links</div>
                        </Col>
                        {quickLinks && quickLinks.length > 0 && quickLinks.map(item => {
                            return <Col onClick={(e) => navigationToUrl(item.url)} md={12}>
                                <div className="footer-quick-sub"><FaAngleRight /> {item.text}</div>
                            </Col>
                        })}
                    </Col>
                    <Col md={5}>
                        <Row>
                            <Col md={12} className="footer-quick-link">Subscribe to ASME’s E-bulletin</Col>
                        </Row>
                        <Row className="footer-email-suscribe">
                            <Col md={8}><button onClick={(e) => navigationToUrl(emailURL.url)}>Subscribe</button></Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Container>
        <Container className="footer-bottom">
            Copyright @ 2020. Association of Small & Medium Enterprises. All Rights Reserved. <NavLink to="/privacystatement">Privacy Statement</NavLink> | <NavLink to="/termofuse">Terms of Use</NavLink>
        </Container>
    </>
    )
}
